import React from "react";
import PropTypes from "prop-types";

export default function WildestLogo({ color, height, width }) {
  return (
    <svg
      width={width || "47"}
      height={height || "41"}
      viewBox="0 0 47 41"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.9164 0.00170898C23.3388 0.00170898 20.8892 2.74364 20.8892 5.93719C20.8892 13.8404 35.3288 16.034 35.3288 27.5178C35.3288 32.6469 32.9437 35.7759 29.4627 35.7759C26.7875 35.7759 25.1437 33.9049 25.1437 31.0017C25.1437 27.9694 27.69 24.5501 27.69 20.6146C27.69 17.292 25.4016 14.6791 22.3396 14.6791C18.3751 14.6791 15.7966 17.9049 15.7966 23.163C15.7966 24.4211 15.9255 25.5178 15.9255 26.4211C15.9255 31.4856 14.4429 34.6469 12.1867 34.6469C10.2528 34.6469 9.15697 33.034 9.15697 30.0662C9.15697 24.0985 12.6057 17.034 18.9875 10.7114L18.4396 9.80816C13.5727 11.292 12.1545 9.80816 7.57764 9.80816C3.64542 9.80816 0.905762 12.5178 0.905762 16.0985C0.905762 18.5501 2.54956 20.4856 4.74129 20.4856C6.35285 20.4856 7.51318 19.3888 7.51318 17.8082C7.51318 16.3243 6.38508 15.1307 4.93468 15.1307C4.09666 15.1307 3.38757 15.5178 2.87187 16.0985C2.61402 14.4856 3.80658 13.2275 5.5793 13.2275C8.60904 13.2275 9.83383 14.1307 12.7991 14.2598C6.57847 21.6469 4.5479 26.6469 4.5479 31.4856C4.5479 35.8727 7.15863 38.8404 11.252 38.8404C15.7644 38.8404 18.5041 34.7114 18.5041 27.4856C18.5041 26.292 18.3429 24.8727 18.3429 23.2275C18.3429 20.1307 19.2454 18.3565 20.9536 18.3565C22.3073 18.3565 23.0809 19.4533 23.0809 21.0985C23.0809 24.5178 20.2445 27.0017 20.2445 31.6791C20.2445 36.5824 23.8222 40.0017 28.9148 40.0017C34.5875 40.0017 38.9387 35.2598 38.9387 28.5178C38.9387 15.5824 24.4991 12.1307 24.4991 6.48558C24.4991 5.22752 25.047 4.5501 26.3685 4.5501C31.4288 4.5501 33.5238 18.2275 41.356 18.2275C44.0312 18.2275 46.0941 16.0662 46.0941 13.292C46.0941 11.2275 44.7081 9.61461 42.9354 9.61461C41.3883 9.61461 40.099 10.8727 40.099 12.4856C40.099 14.1307 41.4527 15.3888 43.1932 15.3243C42.9032 15.9694 42.2908 16.3243 41.5494 16.3243C36.0701 16.3888 36.0379 0.00170898 26.9164 0.00170898Z" />
    </svg>
  );
}

WildestLogo.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

WildestLogo.defaultProps = {
  color: "",
  height: undefined,
  width: undefined,
};
