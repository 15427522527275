import React from "react";
import PropTypes from "prop-types";

export default function Bag({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{
        enableBackground: "new 0 0 20 20",
      }}
      xmlSpace="preserve"
    >
      <path d="M18.2 4.7c-.1-.1-.1-.2-.1-.2l-2.5-3.3c-.1-.2-.3-.3-.6-.3H5c-.3 0-.5.1-.6.3L1.9 4.5s0 .1-.1.1v.1c0 .1-.1.2-.1.3v11.7c0 .6.3 1.3.7 1.7.5.5 1.1.7 1.7.7h11.7c.7 0 1.3-.3 1.7-.7.5-.5.7-1.1.7-1.7V5c.1-.1.1-.2 0-.3zM5.4 2.5h9.2l1.3 1.7H4.1l1.3-1.7zm11 14.8c-.2.2-.4.3-.6.3H4.2c-.2 0-.4-.1-.6-.3-.2-.2-.3-.4-.3-.6V5.8h13.4v10.9c0 .2-.1.4-.3.6z" />
      <path d="M13.3 7.5c-.4 0-.8.4-.8.8 0 .7-.3 1.3-.7 1.8-1 1-2.6 1-3.6 0-.5-.4-.7-1.1-.7-1.8 0-.4-.4-.8-.8-.8s-.8.4-.8.8c0 1.1.4 2.1 1.2 2.9.8.8 1.8 1.2 2.9 1.2 1.1 0 2.1-.4 2.9-1.2.8-.8 1.2-1.8 1.2-2.9 0-.4-.3-.8-.8-.8z" />
    </svg>
  );
}

Bag.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Bag.defaultProps = {
  width: "24",
  height: "24",
};
