import React from "react";

export default function WisdomLogo() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4832 9.2029C12.0856 8.77209 12.6568 8.3618 13.2176 7.95151C13.3007 7.88997 13.4045 7.80791 13.498 7.86945C14.1108 8.32077 14.8585 8.63875 15.1701 9.40804C15.5128 10.2697 15.2739 11.162 14.4846 11.7262C12.0129 13.5007 9.5411 15.2547 7.05895 17.0189C6.46697 17.4395 5.8023 17.3472 5.40765 16.824C5.04415 16.3625 5.15839 15.6034 5.69844 15.2034C7.53669 13.8597 9.38532 12.5468 11.2236 11.2236C11.5767 10.9774 11.9298 10.7312 12.2725 10.4748C12.7295 10.1363 12.7295 10.1055 12.2414 9.76705C12.044 9.62344 11.8467 9.4901 11.6494 9.35676C11.6078 9.32598 11.5767 9.28495 11.4832 9.2029Z"
        fill="#192617"
      />
      <path
        d="M7.80657 10.1461C8.15968 10.3923 8.48163 10.6077 8.7932 10.8436C8.95937 10.9667 9.07361 11.1 8.81397 11.2744C8.31546 11.6129 7.82734 11.9616 7.33922 12.3207C7.19382 12.4232 7.07958 12.4745 6.9238 12.3617C6.49799 12.0334 6.03064 11.777 5.67753 11.3667C4.96093 10.5256 5.06478 9.30502 5.94755 8.64855C7.84811 7.25356 9.76945 5.89961 11.6804 4.53539C12.2516 4.1251 12.8228 3.71481 13.4044 3.31478C13.9964 2.90449 14.6714 2.98655 15.0557 3.49941C15.4504 4.03279 15.3257 4.74054 14.7441 5.16108C12.6566 6.6689 10.5587 8.1562 8.46086 9.66402C8.25315 9.80762 8.04544 9.96148 7.80657 10.1461Z"
        fill="#192617"
      />
      <path
        d="M14.1524 17.244C13.9031 17.2543 13.685 17.162 13.4773 17.0184C12.8957 16.5978 12.3037 16.1875 11.7118 15.7875C11.4833 15.6336 11.504 15.5208 11.7118 15.3772C12.1791 15.0592 12.6361 14.7207 13.093 14.3925C13.3111 14.2386 13.4981 14.1874 13.7266 14.372C14.1004 14.6695 14.5159 14.9156 14.8794 15.2233C15.2532 15.5413 15.409 15.9619 15.2325 16.4542C15.0559 16.9466 14.6509 17.244 14.1524 17.244Z"
        fill="#192617"
      />
      <path
        d="M5.22062 4.16606C5.21023 3.2942 6.12416 2.70953 6.88231 3.1506C7.55737 3.55063 8.18051 4.05323 8.82441 4.4943C9.04251 4.64816 8.96981 4.77124 8.80364 4.89433C8.30513 5.25333 7.79624 5.61234 7.29773 5.9816C7.14195 6.09443 7.01732 6.06366 6.88231 5.96108C6.4565 5.64311 6.02031 5.34565 5.5945 5.01742C5.32447 4.81227 5.21023 4.51481 5.22062 4.16606Z"
        fill="#192617"
      />
    </svg>
  );
}
