import React from "react";
import PropTypes from "prop-types";

export default function Plus({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={width}
      height={height}
      style={{
        enableBackground: "new 0 0 20 20",
      }}
      xmlSpace="preserve"
    >
      <path
        fill={color}
        d="M16.5 9.2h-6.2V3c0-.4-.4-.8-.8-.8s-.8.4-.8.8v6.2H2.5c-.4 0-.8.4-.8.8s.4.8.8.8h6.2V17c0 .4.4.8.8.8s.8-.4.8-.8v-6.2h6.2c.4 0 .8-.4.8-.8s-.4-.8-.8-.8z"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
        }}
      />
    </svg>
  );
}

Plus.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Plus.defaultProps = {
  color: "#FF7817",
  width: "24",
  height: "24",
};
