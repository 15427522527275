import React from "react";
import PropTypes from "prop-types";

export default function Storefront(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <rect
          x="2.5"
          y="1.66667"
          width="15"
          height="16.6667"
          rx="2"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeMiterlimit="10"
        />
        <path
          d="M2.5 3.33334C2.5 2.41286 3.24619 1.66667 4.16667 1.66667H7.5V5.83334C7.5 7.21405 6.38071 8.33334 5 8.33334V8.33334C3.61929 8.33334 2.5 7.21405 2.5 5.83334V3.33334Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeMiterlimit="10"
        />
        <path
          d="M7.5 1.66667H12.5V5.83334C12.5 7.21405 11.3807 8.33334 10 8.33334V8.33334C8.61929 8.33334 7.5 7.21405 7.5 5.83334V1.66667Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeMiterlimit="10"
        />
        <path
          d="M13.3335 18.3333L6.66683 18.3333L6.66683 14.6667C6.66683 13.0098 8.00997 11.6667 9.66683 11.6667L10.3335 11.6667C11.9904 11.6667 13.3335 13.0098 13.3335 14.6667L13.3335 18.3333Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeMiterlimit="10"
        />
        <path
          d="M12.5 1.66667H15.5C16.6046 1.66667 17.5 2.5621 17.5 3.66667V5.83334C17.5 7.21405 16.3807 8.33334 15 8.33334V8.33334C13.6193 8.33334 12.5 7.21405 12.5 5.83334V1.66667Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}

Storefront.propTypes = {
  color: PropTypes.string,
};

Storefront.defaultProps = {
  color: "",
};
