import React from "react";
import PropTypes from "prop-types";

export default function Archive({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      width={width}
      height={height}
      y={0}
      viewBox="0 0 20 20"
      style={{
        enableBackground: "new 0 0 20 20",
      }}
      xmlSpace="preserve"
    >
      <style>{"\n    .st0{fill-rule:evenodd;clip-rule:evenodd}\n  "}</style>
      <path
        className="st0"
        d="M8.3 10.8h3.3c.4 0 .8-.4.8-.8s-.4-.8-.8-.8H8.3c-.4 0-.8.4-.8.8s.4.8.8.8z"
      />
      <path
        className="st0"
        d="M19.2 1.7H.8c-.4 0-.8.4-.8.8v4.2c0 .4.4.8.8.8h.9v10c0 .4.4.8.8.8h15c.4 0 .8-.4.8-.8v-10h.9c.4 0 .8-.4.8-.8V2.5c0-.4-.4-.8-.8-.8zm-2.5 15H3.3V7.5h13.4v9.2zm1.7-10.8H1.6V3.3h16.7v2.6z"
      />
    </svg>
  );
}

Archive.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Archive.defaultProps = {
  width: "24",
  height: "24",
};
