import React from "react";
import PropTypes from "prop-types";

export default function Pet(props) {
  const { color, width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83394 17.5838C6.62737 17.5149 6.35193 17.446 6.14535 17.3083C5.80106 17.1017 5.59448 16.7575 5.45676 16.4132C5.25019 15.8623 5.18133 15.3803 5.25019 14.7605C5.31905 14.1408 5.66334 13.6588 6.0765 13.2456C6.62737 12.6259 7.17824 12.075 7.66025 11.3176C7.79797 11.0422 7.93569 10.8356 8.14226 10.5601C8.34884 10.2158 8.62428 9.94041 9.03743 9.73384C9.79488 9.25182 10.5523 9.32068 11.2409 9.87155C11.6541 10.2158 11.9984 10.629 12.2738 11.111C12.687 11.8685 13.2378 12.4882 13.8576 13.0391C14.2019 13.3834 14.5462 13.7277 14.6839 14.1408C15.0282 15.1048 14.8904 16 14.3396 16.8263C13.9264 17.446 13.2378 17.6526 12.5492 17.5149C11.9984 17.446 11.4475 17.2395 10.8966 17.1017C10.4146 16.964 9.9326 16.964 9.45059 17.1017C8.96857 17.2395 8.48656 17.3772 8.00455 17.5149C7.93569 17.5149 7.86683 17.5149 7.79797 17.5838C7.38482 17.5838 7.10938 17.5838 6.83394 17.5838Z"
        stroke={color || "#27272F"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6151 3.67431C14.5462 4.7072 14.2708 5.67122 13.3068 6.29095C12.4805 6.84183 11.5853 6.56639 11.1722 5.67122C10.8279 4.98263 10.8967 4.22518 11.1033 3.46774C11.3099 2.84801 11.6542 2.29713 12.2739 1.95284C12.8248 1.60854 13.4445 1.60854 13.9265 2.0217C14.2708 2.29713 14.4774 2.77915 14.5462 3.1923C14.6151 3.33002 14.6151 3.46774 14.6151 3.67431Z"
        stroke={color || "#27272F"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.31909 3.94973C5.31909 3.39886 5.45681 2.91685 5.73225 2.43483C6.35198 1.47081 7.38486 1.40195 8.14231 2.29711C8.96862 3.26114 9.10634 4.98262 8.34889 5.94664C8.00459 6.42865 7.52258 6.70409 6.90285 6.56637C6.42084 6.42865 6.07654 6.08436 5.8011 5.67121C5.45681 5.18919 5.31909 4.56946 5.31909 3.94973Z"
        stroke={color || "#27272F"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M18.3333 8.70104C18.2645 9.04534 18.1956 9.32077 18.1267 9.66507C17.989 10.1471 17.6447 10.5602 17.2316 10.8357C16.4741 11.3177 15.6478 11.1111 15.3724 10.2848C15.0281 9.45849 15.1658 8.63218 15.579 7.80588C15.7855 7.46158 15.9921 7.18614 16.3364 7.04843C16.9561 6.70413 17.7136 6.91071 18.0579 7.53044C18.1956 7.80588 18.2645 8.08131 18.3333 8.35675C18.3333 8.42561 18.3333 8.42561 18.3333 8.49447C18.3333 8.56332 18.3333 8.63218 18.3333 8.70104Z"
        stroke={color || "#27272F"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M4.83705 9.25192C4.83705 9.73394 4.76819 10.1471 4.49276 10.4914C4.0796 11.1111 3.39101 11.2488 2.77128 10.8357C2.15155 10.4225 1.87612 9.87165 1.7384 9.18306C1.60068 8.63219 1.66954 8.08132 1.87612 7.59931C2.28927 6.84186 3.04672 6.63528 3.73531 7.04843C4.35504 7.46159 4.63047 8.08132 4.83705 8.76991C4.83705 8.97649 4.83705 9.18306 4.83705 9.25192Z"
        stroke={color || "#27272F"}
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

Pet.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Pet.defaultProps = {
  color: "",
  width: 20,
  height: 20,
};
