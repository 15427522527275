import React from "react";
import PropTypes from "prop-types";

export default function Arrow(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L9.5 12.5L13 9"
        stroke={color || "#9B9694"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Arrow.propTypes = {
  color: PropTypes.string,
};

Arrow.defaultProps = {
  color: "",
};
