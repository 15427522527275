import React from "react";
import PropTypes from "prop-types";

export default function Home(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.056 15V10.8H31.646V15H29.994V5.452H31.646V9.33H36.056V5.452H37.708V15H36.056ZM42.157 7.664C41.513 8.34533 41.191 9.19933 41.191 10.226C41.191 11.2527 41.513 12.1113 42.157 12.802C42.801 13.4833 43.599 13.824 44.551 13.824C45.5124 13.824 46.315 13.4833 46.959 12.802C47.603 12.1113 47.925 11.2527 47.925 10.226C47.925 9.19933 47.6124 8.34533 46.987 7.664C46.3617 6.97333 45.5544 6.628 44.565 6.628C43.613 6.628 42.8104 6.97333 42.157 7.664ZM39.497 10.226C39.497 8.83533 39.9777 7.64067 40.939 6.642C41.9097 5.64333 43.123 5.144 44.579 5.144C46.091 5.144 47.309 5.64333 48.233 6.642C49.157 7.64067 49.619 8.83533 49.619 10.226C49.619 11.6353 49.1337 12.8347 48.163 13.824C47.1924 14.8133 45.993 15.308 44.565 15.308C43.725 15.308 42.969 15.1587 42.297 14.86C41.6344 14.552 41.1024 14.1507 40.701 13.656C40.309 13.152 40.0104 12.6107 39.805 12.032C39.5997 11.444 39.497 10.842 39.497 10.226ZM60.2366 5.452L61.3566 15H59.7606L59.2566 9.946C59.2286 9.67533 59.1866 9.23667 59.1306 8.63C59.0746 8.02333 59.0466 7.70133 59.0466 7.664H58.9766C58.7433 8.51333 58.5193 9.27867 58.3046 9.96L56.7226 15H55.4906L53.8946 9.848C53.5493 8.79333 53.3346 8.06533 53.2506 7.664H53.1526C53.1246 8.27067 53.0686 8.994 52.9846 9.834L52.4526 15H50.9406L52.0466 5.452H53.9366L55.6166 10.646C55.8219 11.29 55.9899 11.8733 56.1206 12.396H56.1766C56.2513 12.0973 56.4239 11.514 56.6946 10.646L58.3606 5.452H60.2366ZM68.6753 5.452V6.908H64.8113V9.358H68.5353V10.772H64.8113V13.544H68.8293V15H63.1593V5.452H68.6753Z"
        stroke={color || "#FF7817"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.49996L10 1.66663L17.5 7.49996V16.6666C17.5 17.1087 17.3244 17.5326 17.0118 17.8451C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8451C2.67559 17.5326 2.5 17.1087 2.5 16.6666V7.49996Z"
        stroke={color || "#FF7817"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.3333V10H12.5V18.3333"
        stroke={color || "#FF7817"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Home.propTypes = {
  color: PropTypes.string,
};

Home.defaultProps = {
  color: "",
};
