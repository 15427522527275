import React from "react";
import PropTypes from "prop-types";

export default function ListView({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.59998"
        y="1.60095"
        width="12.8"
        height="4.8"
        rx="1"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <rect
        x="1.59998"
        y="9.93036"
        width="12.8"
        height="4.8"
        rx="1"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

ListView.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

ListView.defaultProps = {
  height: 16,
  width: 16,
  color: "#8F978C",
};
