import React from "react";
import PropTypes from "prop-types";

export default function Sort({ className, color }) {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61959 6.06571C9.30717 6.37813 8.80064 6.37813 8.48822 6.06571L6.35391 
          3.93139V18C6.35391 18.4419 5.99573 18.8 5.55391 18.8C5.11208 18.8 4.75391 
          18.4419 4.75391 18V3.93139L2.61959 6.06571C2.30717 6.37813 1.80064 6.37813 
          1.48822 6.06571C1.1758 5.75329 1.1758 5.24676 1.48822 4.93434L4.98777 1.43479L4.98822 
          1.43434L4.98864 1.43391L5.55391 0.868652L6.11959 1.43434L9.61959 4.93434C9.93201 5.24676 
          9.93201 5.75329 9.61959 6.06571ZM16.3286 18.5657L15.7629 19.1314L15.1972 18.5657L11.6972 
          15.0657C11.3848 14.7533 11.3848 14.2468 11.6972 13.9343C12.0096 13.6219 12.5162 13.6219 
          12.8286 13.9343L14.9629 16.0687V2.00002C14.9629 1.5582 15.3211 1.20002 15.7629 1.20002C16.2047 
          1.20002 16.5629 1.5582 16.5629 2.00002V16.0687L18.6972 13.9343C19.0096 13.6219 19.5162 13.6219 
          19.8286 13.9343C20.141 14.2468 20.141 14.7533 19.8286 15.0657L16.3286 18.5657Z"
      />
    </svg>
  );
}

Sort.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Sort.defaultProps = {
  className: "",
  color: "",
};
