import React from "react";
import PropTypes from "prop-types";

export default function Filter({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={7.296}
        cy={6.903}
        r={3}
        stroke="#8F978C"
        strokeWidth={1.6}
        strokeLinecap="round"
      />
      <path
        d="M2.296 6.903h1.5"
        stroke="#8F978C"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M10.296 6.903h8"
        stroke="#8F978C"
        strokeWidth={1.6}
        strokeLinecap="round"
      />
      <circle
        cx={13.296}
        cy={15.903}
        r={3}
        transform="rotate(-180 13.296 15.903)"
        stroke="#8F978C"
        strokeWidth={1.6}
        strokeLinecap="round"
      />
      <path
        d="M18.296 15.903h-1.5"
        stroke="#8F978C"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M10.296 15.903h-8"
        stroke="#8F978C"
        strokeWidth={1.6}
        strokeLinecap="round"
      />
    </svg>
  );
}

Filter.propTypes = {
  size: PropTypes.string,
};

Filter.defaultProps = {
  size: "21",
};
