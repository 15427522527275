import React from "react";
import PropTypes from "prop-types";

export default function Dots({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2 4.6a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6Zm0 7.2a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6Zm-1.8 5.4a1.8 1.8 0 1 0 3.6 0 1.8 1.8 0 0 0-3.6 0Z"
        fill="#8F978C"
      />
    </svg>
  );
}

Dots.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Dots.defaultProps = {
  width: "20",
  height: "20",
};
