import React from "react";
import PropTypes from "prop-types";

export default function Arrow({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.506 3.436a.75.75 0 0 1 1.058.07l7 8a.75.75 0 0 1 0 .988l-7 8a.75.75 0 1 1-1.128-.988l5.911-6.756H4a.75.75 0 0 1 0-1.5h14.347l-5.911-6.756a.75.75 0 0 1 .07-1.058Z"
        fill="#030D45"
      />
    </svg>
  );
}

Arrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Arrow.defaultProps = {
  width: "24",
  height: "24",
};
