import React from "react";
import PropTypes from "prop-types";

export default function FerretIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 8.89941C7.27614 8.89941 7.5 8.67556 7.5 8.39941C7.5 8.12327 7.27614 7.89941 7 7.89941C6.72386 7.89941 6.5 8.12327 6.5 8.39941C6.5 8.67556 6.72386 8.89941 7 8.89941Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M13 8.89941C13.2761 8.89941 13.5 8.67556 13.5 8.39941C13.5 8.12327 13.2761 7.89941 13 7.89941C12.7239 7.89941 12.5 8.12327 12.5 8.39941C12.5 8.67556 12.7239 8.89941 13 8.89941Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9001 12.9994C11.9001 11.8994 11.0001 11.8994 10.0001 11.8994C9.0001 11.8994 8.1001 11.9994 8.1001 12.9994C8.1001 14.0994 10.0001 14.0994 10.0001 14.0994C10.0001 14.0994 11.9001 14.0994 11.9001 12.9994Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 16.4992V14.1992"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6 5.99959C17.7 5.59959 17.7 4.99959 17.8 4.49959C18.1 2.89959 18.7 1.69959 16.7 1.39959C14.9 1.09959 13.1 1.89959 12.7 3.19959C11.8 2.99959 10.9 2.89959 10 2.89959C9.1 2.89959 8.2 2.99959 7.3 3.19959C6.8 1.89959 5.1 0.999589 3.3 1.39959C1.3 1.79959 1.9 2.99959 2.2 4.49959C2.3 5.09959 2.3 5.59959 2.4 5.99959C1.5 6.99959 1 8.19959 1 9.49959C1 13.1996 5.9 16.5996 10 16.5996C14.1 16.5996 19 13.1996 19 9.49959C19 8.29959 18.5 7.09959 17.6 5.99959Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.9002 5.69936C9.0002 5.49936 3.4002 4.99936 3.8002 8.39936C4.3002 11.9994 8.7002 9.99936 9.9002 9.79936C11.1002 9.99936 15.7002 11.8994 16.2002 8.39936C16.6002 4.99936 10.8002 5.49936 9.9002 5.69936Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

FerretIcon.propTypes = {
  color: PropTypes.string,
};

FerretIcon.defaultProps = {
  color: "",
};
