import React from "react";
import PropTypes from "prop-types";

export default function Globe({ className, color, size, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
    >
      <circle
        cx="8.58457"
        cy="8.13975"
        r="6.4"
        stroke={fill ? "white" : color || "#8F978C"}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M14.1854 7.7396H2.98535"
        stroke={fill ? "white" : color || "#8F978C"}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <ellipse
        cx="8.58457"
        cy="8.13975"
        rx="2.4"
        ry="6.4"
        stroke={fill ? "white" : color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Globe.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  fill: PropTypes.bool,
};

Globe.defaultProps = {
  className: "",
  color: "",
  size: 20,
  fill: false,
};
