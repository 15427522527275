import React from "react";
import PropTypes from "prop-types";

export default function Menu(props) {
  const { color } = props;
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8.25H24"
        stroke={color || "white"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 15.75H24"
        stroke={color || "white"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 23.25H24"
        stroke={color || "white"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Menu.propTypes = {
  color: PropTypes.string,
};

Menu.defaultProps = {
  color: "",
};
