import React from "react";
import PropTypes from "prop-types";

export default function Quote({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.718.526v2.201c-3.224 1.138-5.752 2.934-7.581 5.39-1.83 2.424-2.744 4.954-2.744 7.589 0 .569.093.988.279 1.258.124.18.264.269.418.269.155 0 .388-.105.698-.314.992-.689 2.248-1.033 3.768-1.033 1.767 0 3.333.703 4.697 2.11 1.365 1.378 2.047 3.024 2.047 4.94 0 2.006-.79 3.773-2.372 5.3-1.55 1.527-3.442 2.29-5.675 2.29-2.604 0-4.852-1.033-6.744-3.098-1.891-2.066-2.837-4.836-2.837-8.309 0-4.042 1.287-7.665 3.86-10.868 2.574-3.204 6.636-5.779 12.186-7.725Zm-22.372 0v2.201c-3.225 1.138-5.752 2.934-7.581 5.39-1.83 2.424-2.744 4.954-2.744 7.589 0 .569.093.988.279 1.258.124.18.263.269.418.269.155 0 .388-.105.698-.314.992-.689 2.248-1.033 3.768-1.033 1.767 0 3.333.703 4.697 2.11 1.364 1.378 2.047 3.024 2.047 4.94 0 2.006-.791 3.773-2.372 5.3-1.55 1.527-3.442 2.29-5.675 2.29-2.604 0-4.853-1.033-6.744-3.098C1.246 25.362.3 22.592.3 19.119c0-4.042 1.287-7.665 3.86-10.868C6.734 5.047 10.796 2.472 16.346.526Z"
        fill={color}
      />
    </svg>
  );
}

Quote.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

Quote.defaultProps = {
  height: "30",
  width: "40",
  color: "#FF7817",
};
