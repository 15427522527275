import React from "react";
import PropTypes from "prop-types";

const LegacyIcon = ({ className, name, color, height, width }) => {
  const localSize = name?.split("-").pop();
  return (
    <svg
      className={className}
      width={width || localSize}
      height={height || localSize}
      viewBox={`0 0 ${width || localSize} ${height || localSize}`}
      fill={color}
    >
      <use href={`#icon-${name}`} />
    </svg>
  );
};

LegacyIcon.defaultProps = {
  className: "",
  color: "#8F978C",
  height: undefined,
  width: undefined,
  name: "location-20",
};

LegacyIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  name: (props, propName, componentName) => {
    let error;
    if (!/-\d+$/.test(props[propName])) {
      error = new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Needs to be in format icon-name-[size]`);
    }
    return error;
  },
};

export default LegacyIcon;
