import React from "react";
import PropTypes from "prop-types";

export default function Email(props) {
  const { color } = props;
  return (
    <svg
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={color || "#ff7817"}
        strokeLinecap="round"
        strokeWidth="2"
      >
        <rect
          height="54.9"
          rx="8"
          width="82.8"
          x="3.99988"
          y="18"
        />
        <path d="m10 18 32.6014 10.2461c1.5614.4908 3.2358.4908 4.7972.0001l32.6014-10.2462" />
        <path d="m32.6317 52.8c8.4157 2.73 17.479 2.73 25.8947 0" />
      </g>
      <ellipse
        cx="27.2368"
        cy="42.15"
        fill="#ff7817"
        rx="3.23684"
        ry="3.15"
      />
      <ellipse
        cx="61.7632"
        cy="42.15"
        fill="#ff7817"
        rx="3.23684"
        ry="3.15"
      />
    </svg>
  );
}

Email.propTypes = {
  color: PropTypes.string,
};

Email.defaultProps = {
  color: "",
};
