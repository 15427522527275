import React from "react";

export default function Logo() {
  return (
    <svg
      width="137"
      height="40"
      viewBox="0 0 137 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.576 10.4257C46.2127 9.42294 45.6599 8.53158 44.9018 7.79938C44.0174 6.95577 42.9434 6.38275 41.7431 6.09624C40.2585 5.74606 38.8212 5.74606 37.4314 6.44642C36.2627 7.03536 35.3466 7.9108 34.5727 8.94542C34.399 9.18418 34.2095 9.40702 34.0041 9.67762C33.6251 9.2001 33.3092 8.77033 32.9618 8.35649C31.9352 7.16269 30.719 6.23949 29.1239 5.93707C28.0499 5.73014 27.0075 5.8734 25.9651 6.17583C24.8122 6.49417 23.8014 7.08311 22.9801 7.95856C21.4165 9.61395 20.8637 11.6195 20.9585 13.8479C21.0375 15.6466 21.6218 17.2701 22.8222 18.639C24.0067 19.9761 25.5071 20.6764 27.2602 20.9152C28.5869 21.0903 29.8188 20.8356 30.956 20.0875C32.0615 19.3553 32.946 18.4003 33.7357 17.3497C34.0199 16.9836 34.0041 16.9995 34.32 17.3656C34.9202 18.0819 35.5046 18.83 36.1837 19.4667C37.2103 20.4217 38.4422 20.9629 39.8636 20.9629C41.443 20.9629 42.896 20.5332 44.1911 19.5781C45.7231 18.448 46.5918 16.8881 46.955 15.0576C47.2235 13.4818 47.113 11.9379 46.576 10.4257ZM43.8279 15.0736C43.354 16.5857 42.4222 17.6203 40.8902 18.0819C39.5793 18.4798 38.4896 18.066 37.5262 17.1587C36.8786 16.5539 36.2784 15.8853 35.5677 15.376C34.4464 14.5483 33.2145 14.6438 32.1721 15.5511C31.6035 16.0445 31.0665 16.5698 30.5295 17.095C29.1555 18.4798 27.5603 18.5435 25.9651 17.5566C25.0491 16.9836 24.4963 16.1082 24.212 15.0895C24.0541 14.5483 24.0225 13.9753 23.9277 13.4182C23.9751 12.0334 24.3542 10.7918 25.3334 9.78904C26.6285 8.46791 28.808 8.00631 30.419 9.58211C30.9717 10.1233 31.5245 10.6804 32.1247 11.1738C33.4356 12.2403 34.8412 12.1448 36.0731 10.9987C36.7049 10.4098 37.305 9.80495 37.9684 9.26377C39.2161 8.19731 41.0482 8.54749 42.1853 9.37519C43.9226 10.6167 44.4438 13.0521 43.8279 15.0736Z"
        fill="#FF7817"
      />
      <path
        d="M19.7581 9.62992C18.0523 9.64584 16.3308 9.62992 14.6251 9.62992C12.9352 9.62992 11.2294 9.62992 9.5395 9.62992C9.0341 9.62992 9.01831 9.64584 9.01831 10.1393C9.01831 10.6805 9.01831 11.2216 9.01831 11.7628C9.01831 12.3518 9.01831 12.3518 9.60268 12.3518C11.9402 12.3518 14.2934 12.3518 16.6309 12.3518C17.0889 12.3518 17.1363 12.4154 17.0573 12.8611C16.473 15.9491 14.1987 18.1138 11.1031 18.4003C7.67584 18.7187 4.59606 16.8882 3.4905 13.4023C2.65343 10.76 2.87454 8.2292 4.32756 5.84162C5.08566 4.61599 6.15964 3.74054 7.48632 3.18344C8.7972 2.62634 10.187 2.5945 11.5611 2.78551C13.6301 3.07202 15.3358 3.99522 16.3782 5.9212C16.4414 6.03262 16.5993 6.17588 16.7257 6.17588C17.5943 6.1918 18.463 6.1918 19.3316 6.1918C19.5685 6.1918 19.6633 6.06446 19.5685 5.8257C18.8578 4.09072 17.768 2.65817 16.1887 1.63947C14.7041 0.668516 13.0457 0.206917 11.2768 0.0636614C9.25522 -0.0955109 7.32838 0.190999 5.54368 1.14603C2.43231 2.78551 0.663414 5.42777 0.126426 8.91364C-0.221036 11.2376 0.14222 13.4501 1.2162 15.5511C2.32176 17.7 3.91693 19.3394 6.12805 20.2945C8.62347 21.3768 11.1978 21.4087 13.7564 20.5491C15.9044 19.8329 17.5311 18.4322 18.6841 16.4743C19.837 14.5165 20.2477 12.3677 20.2477 10.1074C20.2319 9.72542 20.1055 9.62992 19.7581 9.62992Z"
        fill="#FF7817"
      />
      <path
        d="M59.7005 18.8778C59.7479 18.9574 59.7637 18.9892 59.7637 19.0051C59.7637 19.3235 59.7795 19.6418 59.7795 19.9602C59.7953 20.4854 59.8269 20.5173 60.3165 20.5173C60.9009 20.5173 61.501 20.5173 62.0854 20.5173C62.5434 20.5173 62.6066 20.4377 62.6066 19.9761C62.6066 14.0549 62.6066 8.14957 62.6066 2.22836C62.6066 1.83043 62.6066 1.4325 62.6066 1.03457C62.6066 0.74806 62.496 0.620722 62.2117 0.620722C61.58 0.620722 60.9482 0.604805 60.3165 0.620722C59.7953 0.636639 59.7795 0.668473 59.7795 1.20966C59.7795 3.32665 59.7795 5.45956 59.7795 7.57655C59.7795 7.73572 59.7795 7.8949 59.7795 8.03815C59.7479 8.05407 59.7005 8.06999 59.6689 8.10182C59.5426 8.00632 59.4162 7.92673 59.3215 7.83123C58.5002 7.00353 57.5526 6.33501 56.3996 6.09625C53.9832 5.5869 51.851 6.20767 50.0821 7.92673C48.9608 9.0091 48.329 10.378 48.0447 11.922C47.7762 13.3863 47.871 14.8189 48.3606 16.1878C49.1503 18.4003 50.6191 19.9602 52.8776 20.6764C54.2043 21.0903 55.5626 21.138 56.905 20.6605C57.8211 20.3263 58.6108 19.7851 59.3215 19.1325C59.4478 19.0051 59.5742 18.9574 59.7005 18.8778ZM57.9948 17.5567C55.91 19.1325 52.846 18.4639 51.551 16.1878C50.398 14.1504 50.5875 11.6673 52.0879 9.88455C53.0356 8.75443 54.3149 8.38833 55.7363 8.46792C57.5526 8.56342 59.1004 9.77313 59.6532 11.524C59.7637 11.8901 59.8743 12.2562 59.9374 12.6382C59.9848 12.8929 59.969 13.1635 59.969 13.3068C59.9848 15.1372 59.3373 16.538 57.9948 17.5567Z"
        fill="#FF7817"
      />
      <path
        d="M136 1.08236C136.016 0.7481 135.889 0.588928 135.542 0.604845C134.958 0.620762 134.389 0.604845 133.805 0.604845C133.236 0.604845 133.22 0.620762 133.22 1.2097C133.22 3.34261 133.22 5.47552 133.22 7.60843C133.22 7.75168 133.189 7.91085 133.157 8.16553C132.825 7.8631 132.573 7.64026 132.32 7.41742C131.562 6.73298 130.709 6.22363 129.698 6.0167C128.593 5.77795 127.503 5.84161 126.429 6.15996C124.265 6.79665 122.749 8.19736 121.912 10.2666C121.122 12.2403 121.075 14.2777 121.833 16.2833C122.796 18.8619 124.644 20.4377 127.345 20.8834C129.288 21.2017 131.009 20.6287 132.478 19.3076C132.667 19.1325 132.873 18.9733 133.062 18.7982C133.11 18.8142 133.157 18.8301 133.204 18.8619C133.204 19.2439 133.22 19.6259 133.204 19.992C133.189 20.3581 133.283 20.5332 133.694 20.5014C134.231 20.4695 134.768 20.5014 135.305 20.5014C135.984 20.5014 135.984 20.4855 135.984 19.801C135.984 13.848 135.984 7.89494 135.984 1.94189C136 1.6713 135.984 1.38479 136 1.08236ZM132.288 16.6972C131.199 18.082 129.73 18.5913 128.008 18.3207C125.86 18.0024 124.376 16.156 124.249 13.8002C124.139 11.7947 124.771 10.1233 126.539 9.02506C127.219 8.61121 127.977 8.43612 128.593 8.46796C130.772 8.42021 132.431 9.614 133.078 11.5877C133.678 13.4341 133.489 15.1691 132.288 16.6972Z"
        fill="#FF7817"
      />
      <path
        d="M102.501 6.9558C100.954 5.98485 99.2794 5.71426 97.5105 6.00077C95.5047 6.33503 93.9411 7.36965 92.8671 9.10463C91.6194 11.142 91.4299 13.3386 92.0458 15.6148C92.8513 18.5754 95.094 20.4695 97.9369 20.8834C100.322 21.2336 102.486 20.6446 104.191 18.8141C104.728 18.2411 105.202 17.6044 105.486 16.8404C105.392 16.8086 105.328 16.7608 105.281 16.7608C104.381 16.7608 103.496 16.7449 102.596 16.7608C102.454 16.7608 102.28 16.8882 102.17 16.9996C100.938 18.464 98.9793 18.7664 97.2736 17.9864C95.9469 17.3657 95.0467 16.3629 94.715 14.8985C94.5887 14.3573 94.636 14.3414 95.1888 14.3414C98.4581 14.3414 101.712 14.3414 104.981 14.3414C105.502 14.3414 105.565 14.2777 105.613 13.7525C105.692 12.6064 105.565 11.4763 105.155 10.3939C104.633 8.97729 103.812 7.76758 102.501 6.9558ZM102.185 11.8742C101.017 11.8742 99.848 11.8742 98.6792 11.8742C97.5105 11.8742 96.3418 11.8742 95.173 11.8742C94.7782 11.8742 94.6834 11.7787 94.8098 11.3967C95.5047 9.23196 97.321 8.07001 99.6427 8.56344C101.143 8.88179 102.075 9.94824 102.549 11.4126C102.659 11.7628 102.517 11.8901 102.185 11.8742Z"
        fill="#FF7817"
      />
      <path
        d="M75.7786 0.604858C72.4461 0.604858 69.1136 0.604858 65.7969 0.604858C65.2126 0.604858 65.1968 0.636693 65.1968 1.20971C65.1968 4.31357 65.1968 7.41743 65.1968 10.5213C65.1968 13.6252 65.1968 16.729 65.1968 19.8329C65.1968 20.4536 65.2284 20.5014 65.8285 20.5014C66.3813 20.5014 66.9341 20.5014 67.4869 20.5014C68.087 20.5014 68.1028 20.4696 68.1028 19.8647C68.1028 17.3975 68.1028 14.9304 68.1186 12.4632C68.1186 11.9061 68.1344 11.9061 68.6714 11.9061C70.9141 11.9061 73.141 11.9061 75.3837 11.9061C75.9049 11.9061 75.9523 11.8424 75.9523 11.349C75.9523 10.9033 75.9523 10.4417 75.9523 9.99602C75.9523 9.15241 75.9523 9.15241 75.0995 9.15241C72.9357 9.15241 70.7878 9.15241 68.624 9.15241C68.1344 9.15241 68.1028 9.10466 68.1028 8.62714C68.1028 7.05134 68.1028 5.47553 68.1186 3.89972C68.1186 3.34262 68.1344 3.34262 68.6714 3.34262C71.0405 3.34262 73.4095 3.34262 75.7786 3.34262C76.2682 3.34262 76.2998 3.31079 76.3156 2.83327C76.3156 2.26025 76.3156 1.68723 76.3156 1.13013C76.3156 0.668527 76.2682 0.620776 75.7786 0.604858Z"
        fill="#FF7817"
      />
      <path
        d="M120.048 11.0625C119.717 8.64305 118.564 6.89216 116.147 6.12813C114.252 5.5392 112.42 6.04855 111.125 7.56068C110.951 7.75169 110.793 7.95861 110.556 8.24512C110.541 8.00637 110.509 7.87903 110.509 7.76761C110.509 7.44926 110.493 7.13092 110.509 6.81257C110.525 6.47831 110.414 6.33506 110.067 6.35097C109.482 6.36689 108.882 6.35097 108.298 6.35097C107.761 6.35097 107.745 6.36689 107.745 6.90808C107.745 9.3275 107.745 11.7628 107.745 14.1822C107.745 16.1082 107.745 18.0342 107.745 19.9443C107.745 20.4377 107.792 20.4855 108.282 20.4855C108.851 20.4855 109.419 20.4855 109.972 20.4855C110.493 20.4855 110.509 20.4696 110.509 19.9443C110.509 17.891 110.493 15.8376 110.509 13.7843C110.509 13.068 110.572 12.3518 110.667 11.6514C110.856 10.2666 111.63 8.88181 113.447 8.54755C114.679 8.32471 115.958 8.5953 116.716 9.83685C117.174 10.6009 117.316 11.4286 117.316 12.304C117.316 14.8826 117.316 17.4453 117.316 20.0239C117.316 20.3581 117.395 20.5014 117.758 20.4855C118.374 20.4536 118.99 20.4855 119.606 20.4696C120.064 20.4696 120.127 20.4059 120.127 19.9284C120.127 17.3498 120.127 14.7871 120.127 12.2085C120.112 11.8424 120.096 11.4445 120.048 11.0625Z"
        fill="#FF7817"
      />
      <path
        d="M85.3653 8.56346C85.3653 7.95861 85.3653 7.33784 85.3653 6.73298C85.3653 6.46239 85.2548 6.2873 84.9863 6.25546C84.0071 6.12813 83.0279 6.11221 82.1908 6.7489C81.6854 7.13091 81.2274 7.56068 80.7536 7.95861C80.7062 7.95861 80.6588 7.97452 80.6272 7.97452C80.6272 7.60843 80.6114 7.22641 80.6272 6.86032C80.643 6.4783 80.5167 6.33505 80.1376 6.35097C79.5374 6.36688 78.9215 6.35097 78.3213 6.35097C77.8317 6.35097 77.8159 6.36688 77.8159 6.86032C77.8159 9.20015 77.8159 11.5241 77.8159 13.8639C77.8159 15.9013 77.8159 17.9387 77.8159 19.992C77.8159 20.4696 77.8633 20.5014 78.3371 20.5173C78.9215 20.5173 79.4901 20.5173 80.0744 20.5173C80.5956 20.5173 80.6272 20.4696 80.6272 19.9443C80.6272 17.7 80.6114 15.4715 80.643 13.2272C80.6588 12.5109 80.7536 11.7787 80.9273 11.0784C81.1958 9.9801 82.0802 8.88181 83.4227 8.84997C83.9281 8.83406 84.4335 8.89772 84.9547 8.92956C85.2232 8.94548 85.3653 8.86589 85.3653 8.56346Z"
        fill="#FF7817"
      />
      <path
        d="M89.4875 6.35095C88.8716 6.36687 88.2556 6.35095 87.6397 6.35095C87.1343 6.35095 87.1343 6.35095 87.1343 6.8603C87.1343 9.04096 87.1343 11.2375 87.1343 13.4182C87.1343 15.5989 87.1343 17.7636 87.1343 19.9443C87.1343 20.5014 87.1501 20.5173 87.7186 20.5173C88.2398 20.5173 88.7768 20.5173 89.298 20.5173C89.914 20.5173 89.9298 20.4854 89.9298 19.8647C89.9298 15.8535 89.9298 11.8424 89.9298 7.81534C89.9298 7.46516 89.9298 7.1309 89.9298 6.78072C89.9298 6.47829 89.7876 6.35095 89.4875 6.35095Z"
        fill="#FF7817"
      />
      <path
        d="M107.381 33.9992C107.208 33.7923 107.208 33.649 107.397 33.458C107.697 33.1715 107.966 32.8691 108.25 32.5667C108.724 32.0892 109.198 31.5957 109.656 31.1182C109.798 30.975 109.877 30.7839 109.593 30.7521C108.992 30.7044 108.424 30.6407 107.95 31.2455C107.413 31.93 106.765 32.5189 106.165 33.1397C106.086 33.2193 105.976 33.2352 105.881 33.283C105.849 33.1715 105.786 33.076 105.786 32.9646C105.77 32.4871 105.786 32.0096 105.786 31.5321C105.786 30.6884 105.786 30.7044 104.949 30.7203C104.681 30.7203 104.586 30.8317 104.586 31.1023C104.586 33.1397 104.586 35.193 104.586 37.2304C104.586 37.3259 104.633 37.5169 104.665 37.5169C104.981 37.5488 105.312 37.5806 105.628 37.5329C105.707 37.5169 105.77 37.2304 105.77 37.0553C105.786 36.4823 105.739 35.9093 105.802 35.3522C105.834 35.1134 106.071 34.9065 106.213 34.6837C106.371 34.7951 106.56 34.8747 106.687 35.0179C107.271 35.7024 107.824 36.4027 108.392 37.1031C108.771 37.5806 109.561 37.7557 110.193 37.4533C110.114 37.3259 110.051 37.2304 109.972 37.1349C109.103 36.1162 108.25 35.0498 107.381 33.9992Z"
        fill="#FF7817"
      />
      <path
        d="M134.246 32.7258C133.472 32.4871 132.778 32.7258 132.146 33.1874C132.083 33.1078 132.035 33.076 132.019 33.0442C131.814 32.5985 131.419 32.8054 131.119 32.8213C131.024 32.8213 130.898 33.1397 130.898 33.3147C130.882 35.3681 130.882 37.4214 130.882 39.4747C130.882 39.9204 130.945 39.9522 131.482 39.9681C131.846 39.9841 132.019 39.8408 132.004 39.4588C131.988 38.8858 131.988 38.3128 132.004 37.7557C132.004 37.5965 132.051 37.4373 132.083 37.2622C133.694 38.0262 134.546 37.9148 135.399 36.8802C136.078 36.0525 136.094 34.4926 135.447 33.6013C135.131 33.1715 134.736 32.885 134.246 32.7258ZM133.394 36.7847C132.604 36.8006 131.941 36.0684 131.941 35.2248C131.956 34.3653 132.556 33.6808 133.315 33.6649C134.167 33.649 134.736 34.2698 134.736 35.2248C134.736 36.1003 134.152 36.7688 133.394 36.7847Z"
        fill="#FF7817"
      />
      <path
        d="M92.9145 33.076C92.8513 33.1238 92.7882 33.1556 92.6302 33.2511C92.5986 33.0442 92.5671 32.9009 92.5671 32.7736C92.5513 32.2802 92.567 31.7867 92.5513 31.3092C92.5355 30.8158 92.4881 30.7839 91.9353 30.7839C91.4773 30.7839 91.4141 30.8317 91.4141 31.3092C91.4141 32.8213 91.4141 34.3335 91.4141 35.8456C91.4141 36.3072 91.3983 36.7688 91.4299 37.2304C91.4457 37.3578 91.572 37.5806 91.6668 37.5965C92.0459 37.6443 92.4723 37.7716 92.6618 37.2304C92.7408 37.2782 92.804 37.31 92.8513 37.3578C93.6568 37.9626 94.7782 37.9467 95.5521 37.31C96.4365 36.5778 96.6103 35.6069 96.3892 34.5404C96.0733 32.8532 94.2886 32.1369 92.9145 33.076ZM93.9253 36.7847C93.1514 36.7847 92.4881 36.0685 92.5039 35.2408C92.5039 34.3812 93.1356 33.665 93.9095 33.665C94.7308 33.665 95.3152 34.3176 95.3152 35.2567C95.2994 36.1162 94.6992 36.7847 93.9253 36.7847Z"
        fill="#FF7817"
      />
      <path
        d="M124.833 32.7258C124.549 32.8213 124.281 33.0123 123.933 33.1874C123.917 32.9646 123.902 32.8213 123.902 32.6621C123.902 32.2005 123.902 31.7389 123.902 31.2773C123.902 30.8157 123.838 30.7521 123.365 30.7521C122.78 30.7521 122.78 30.7521 122.78 31.2614C122.78 33.2033 122.78 35.1612 122.78 37.1031C122.78 37.4373 122.828 37.6283 123.223 37.6124C123.854 37.5806 123.886 37.5806 123.902 37.0712C123.917 36.3231 123.902 35.5909 123.933 34.8428C123.949 34.3653 124.123 33.9514 124.597 33.7445C125.323 33.4262 125.986 33.8082 126.05 34.62C126.097 35.4317 126.065 36.2435 126.081 37.0394C126.081 37.5487 126.113 37.5806 126.618 37.5806C126.729 37.5806 126.855 37.6283 126.934 37.5806C127.045 37.5169 127.218 37.4055 127.218 37.31C127.218 36.2435 127.25 35.1771 127.139 34.1265C126.997 33.0282 125.892 32.3756 124.833 32.7258Z"
        fill="#FF7817"
      />
      <path
        d="M115.215 32.7576C114.978 32.8531 114.757 32.9964 114.504 33.1396C114.283 32.8053 113.92 32.678 113.509 32.8054C113.162 32.9009 113.288 33.2192 113.288 33.442C113.272 34.6677 113.288 35.8774 113.288 37.103C113.288 37.5964 113.32 37.6124 113.888 37.6283C114.378 37.6283 114.409 37.5964 114.409 37.1189C114.409 36.3708 114.409 35.6068 114.425 34.8587C114.441 34.3971 114.631 33.9991 115.057 33.7922C115.831 33.442 116.494 33.8877 116.526 34.7473C116.557 35.5113 116.542 36.2912 116.542 37.0553C116.542 37.6124 116.557 37.6283 117.126 37.6283C117.236 37.6283 117.347 37.6442 117.442 37.6124C117.537 37.5646 117.679 37.4373 117.679 37.3577C117.679 36.2912 117.726 35.2089 117.616 34.1424C117.489 32.9804 116.273 32.3437 115.215 32.7576Z"
        fill="#FF7817"
      />
      <path
        d="M100.906 32.7895C100.258 32.7577 100.211 32.8054 100.037 33.4421C99.9738 33.6491 99.9107 33.8401 99.8317 34.047C99.6106 34.6837 99.4053 35.3044 99.1526 36.0526C99.042 35.8456 98.963 35.7183 98.9315 35.591C98.663 34.7951 98.4103 34.0151 98.126 33.2193C98.0628 33.0601 97.9365 32.8532 97.7943 32.8214C97.51 32.7577 97.2099 32.8054 96.8467 32.8054C96.9099 33.0124 96.9257 33.1079 96.9572 33.2034C97.4311 34.5404 97.9207 35.8615 98.3787 37.1986C98.4576 37.4214 98.4419 37.7239 98.3787 37.9467C98.2207 38.5038 97.9838 39.045 97.7943 39.5862C97.7627 39.6817 97.8101 39.8727 97.8733 39.9045C98.2207 40.1114 98.7735 39.8568 98.9157 39.4588C99.3579 38.265 99.7843 37.0713 100.227 35.8934C100.558 35.002 100.874 34.1107 101.206 33.2034C101.348 32.8532 101.301 32.8054 100.906 32.7895Z"
        fill="#FF7817"
      />
      <path
        d="M87.3081 3.69282C87.7188 3.67691 88.1294 3.69282 88.5242 3.69282C88.9507 3.69282 89.3613 3.69282 89.7877 3.69282C90.0878 3.69282 90.2142 3.56549 90.2142 3.26306C90.1984 2.48311 90.1984 1.70317 90.2142 0.923226C90.2142 0.620798 90.0878 0.49346 89.7877 0.509378C88.9665 0.525295 88.1294 0.525295 87.3081 0.509378C86.9922 0.509378 86.8501 0.636716 86.8501 0.95506C86.8659 1.735 86.8659 2.51495 86.8659 3.29489C86.8659 3.5814 87.008 3.69282 87.3081 3.69282Z"
        fill="#FF7817"
      />
      <path
        d="M121.296 35.0657C120.917 34.8428 120.474 34.7155 120.079 34.4927C119.89 34.3812 119.732 34.1584 119.653 33.9515C119.621 33.856 119.811 33.6172 119.953 33.5535C120.206 33.4262 120.506 33.474 120.648 33.7446C120.853 34.0947 121.153 34.0947 121.501 34.0947C121.801 34.0788 121.864 33.9356 121.769 33.6809C121.548 33.0919 121.106 32.7895 120.506 32.6781C119.353 32.4553 118.421 33.2193 118.563 34.2698C118.658 34.9224 119.116 35.2249 119.653 35.4477C119.969 35.575 120.285 35.7024 120.585 35.8775C120.838 36.0366 120.932 36.3231 120.79 36.6097C120.648 36.8802 120.38 37.0235 120.095 36.9121C119.874 36.8325 119.685 36.6415 119.527 36.4664C119.353 36.2754 118.769 36.1003 118.563 36.2754C118.295 36.4823 118.5 36.737 118.595 36.9439C118.926 37.5965 119.495 37.7875 120.174 37.7875C120.332 37.7716 120.49 37.7716 120.648 37.7557C121.296 37.6761 121.754 37.2623 121.912 36.5778C122.054 36.0048 121.833 35.384 121.296 35.0657Z"
        fill="#FF7817"
      />
      <path
        d="M128.987 32.7736C128.687 32.7736 128.403 32.7259 128.403 33.1875C128.419 33.856 128.403 34.5404 128.403 35.209C128.403 35.7661 128.403 36.3232 128.403 36.8803C128.403 37.6443 128.292 37.5965 129.177 37.5965C129.445 37.5965 129.587 37.501 129.587 37.1986C129.587 35.8616 129.587 34.5245 129.587 33.1875C129.587 32.7577 129.287 32.7736 128.987 32.7736Z"
        fill="#FF7817"
      />
      <path
        d="M111.93 35.2248C111.93 34.5563 111.93 33.8718 111.93 33.2033C111.93 32.9168 111.819 32.7735 111.535 32.7895C111.409 32.7895 111.267 32.7895 111.14 32.7895C110.903 32.7735 110.809 32.885 110.809 33.1237C110.809 34.5244 110.809 35.9252 110.824 37.3259C110.824 37.4214 110.982 37.5487 111.109 37.6124C111.219 37.6601 111.361 37.6124 111.504 37.6283C111.819 37.6442 111.962 37.5169 111.946 37.1826C111.93 36.5141 111.93 35.8774 111.93 35.2248Z"
        fill="#FF7817"
      />
      <path
        d="M129.019 30.6248C128.355 30.6248 128.355 30.6248 128.355 31.2774C128.355 31.7708 128.403 31.7867 129.035 31.7867C129.603 31.7867 129.603 31.7867 129.587 31.0864C129.603 30.6248 129.603 30.6248 129.019 30.6248Z"
        fill="#FF7817"
      />
      <path
        d="M111.393 30.6248C110.777 30.6248 110.777 30.6248 110.777 31.15C110.777 31.7549 110.793 31.7867 111.345 31.7867C111.993 31.7867 111.993 31.7867 111.993 31.2137C111.993 30.6248 111.993 30.6248 111.393 30.6248Z"
        fill="#FF7817"
      />
    </svg>
  );
}
