import React from "react";
import PropTypes from "prop-types";

export default function CloseSmall(props) {
  const { color } = props;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3.75L11.3571 12.1071"
        stroke={color || "#C83E35"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M11.3571 3.75L3 12.1071"
        stroke={color || "#C83E35"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

CloseSmall.propTypes = {
  color: PropTypes.string,
};

CloseSmall.defaultProps = {
  color: "",
};
