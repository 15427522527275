import React from "react";
import PropTypes from "prop-types";

export default function Edit(props) {
  const { color, onClick, width, height } = props;
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1182 1.85359C16.7277 1.46307 16.0945 1.46307 15.704 1.85359L13.3389 4.21874L15.5333 6.41321L17.8985 4.04806C18.289 3.65754 18.289 3.02437 17.8985 2.63385L17.1182 1.85359ZM13.7778 8.16879L11.5833 5.97432L2.56933 14.9883C2.4368 15.1208 2.34418 15.2879 2.30204 15.4705L1.70819 18.0439L4.28155 17.45C4.46417 17.4079 4.63127 17.3153 4.7638 17.1828L13.7778 8.16879Z"
        fill={color || "#9B9694"}
      />
    </svg>
  );
}

Edit.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
};

Edit.defaultProps = {
  color: "#9B9694",
  onClick: null,
  width: "20",
  height: "21",
};
