import React from "react";

export default function LogoWithTm() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 140 40"
      width="140"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#ff7817">
        <path d="m46.576 10.4258c-.3633-1.00274-.9161-1.8941-1.6742-2.62629-.8844-.84362-1.9584-1.41664-3.1587-1.70315-1.4846-.35018-2.9219-.35018-4.3117.35018-1.1687.58894-2.0848 1.46439-2.8587 2.49901-.1737.23875-.3632.46159-.5686.73219-.379-.47752-.6949-.90728-1.0423-1.32113-1.0266-1.19379-2.2428-2.11699-3.8379-2.41942-1.074-.20692-2.1164-.06367-3.1588.23876-1.1529.31834-2.1637.90728-2.985 1.78273-1.5636 1.65539-2.1164 3.66092-2.0216 5.88942.079 1.7986.6633 3.4222 1.8637 4.791 1.1845 1.3371 2.6849 2.0374 4.438 2.2762 1.3267.1751 2.5586-.0796 3.6958-.8277 1.1055-.7322 1.99-1.6872 2.7797-2.7378.2842-.3661.2684-.3501.5843.016.6002.7162 1.1846 1.4643 1.8637 2.101 1.0266.9551 2.2585 1.4963 3.6799 1.4963 1.5794 0 3.0324-.4298 4.3275-1.3848 1.532-1.1302 2.4007-2.6901 2.7639-4.5205.2685-1.5758.158-3.1198-.379-4.632zm-2.7481 4.6479c-.4739 1.5121-1.4057 2.5467-2.9377 3.0083-1.3109.398-2.4006-.0159-3.364-.9232-.6476-.6048-1.2478-1.2733-1.9585-1.7827-1.1213-.8277-2.3532-.7322-3.3956.1751-.5686.4934-1.1056 1.0187-1.6426 1.544-1.374 1.3848-2.9692 1.4484-4.5644.4616-.916-.5731-1.4688-1.4485-1.7531-2.4672-.1579-.5412-.1895-1.1142-.2843-1.6713.0474-1.3848.4265-2.6264 1.4057-3.62914 1.2951-1.32113 3.4746-1.78273 5.0856-.20693.5527.54117 1.1055 1.09827 1.7057 1.59177 1.3109 1.0664 2.7165.9709 3.9484-.1751.6318-.589 1.2319-1.19382 1.8953-1.73501 1.2477-1.06645 3.0798-.71628 4.2169.11142 1.7373 1.24159 2.2585 3.67689 1.6426 5.69839z" />
        <path d="m19.7581 9.62992c-1.7058.01592-3.4273 0-5.133 0-1.6899 0-3.3957 0-5.0856 0-.5054 0-.52119.01592-.52119.50938v1.6235c0 .589 0 .589.58437.589h7.02822c.458 0 .5054.0636.4264.5093-.5843 3.088-2.8586 5.2527-5.9542 5.5392-3.42726.3184-6.50704-1.5121-7.6126-4.998-.83707-2.6423-.61596-5.1731.83706-7.56068.7581-1.22563 1.83208-2.10108 3.15876-2.65818 1.31088-.5571 2.70068-.58894 4.07478-.39793 2.069.28651 3.7747 1.20971 4.8171 3.13569.0632.11142.2211.25468.3475.25468.8686.01592 1.7373.01592 2.6059.01592.2369 0 .3317-.12734.2369-.3661-.7107-1.73498-1.8005-3.16753-3.3798-4.18623-1.4846-.970954-3.143-1.432553-4.9119-1.5758086-2.02158-.1591723-3.94842.1273376-5.73312 1.0823686-3.11137 1.63948-4.880266 4.28174-5.417254 7.76761-.347462 2.32396.015794 4.53646 1.089774 6.63746 1.10556 2.1489 2.70073 3.7883 4.91185 4.7434 2.49542 1.0823 5.06975 1.1142 7.62835.2546 2.148-.7162 3.7747-2.1169 4.9277-4.0748 1.1529-1.9578 1.5636-4.1066 1.5636-6.3669-.0158-.38198-.1422-.47748-.4896-.47748z" />
        <path d="m59.7005 18.8779c.0474.0796.0632.1114.0632.1273 0 .3184.0158.6367.0158.9551.0158.5252.0474.5571.537.5571h1.7689c.458 0 .5212-.0796.5212-.5412 0-5.9212 0-11.82651 0-17.74772 0-.39793 0-.79586 0-1.19379 0-.286508-.1106-.413846-.3949-.413846-.6317 0-1.2635-.015917-1.8952 0-.5212.015917-.537.047752-.537.588936v6.36689.4616c-.0316.01592-.079.03184-.1106.06367-.1263-.0955-.2526-.17509-.3474-.27059-.8213-.8277-1.7689-1.49622-2.9219-1.73498-2.4164-.50935-4.5486.11142-6.3175 1.83048-1.1213 1.08237-1.7531 2.45125-2.0374 3.99525-.2685 1.4644-.1737 2.8969.3159 4.2658.7897 2.2125 2.2585 3.7724 4.517 4.4887 1.3267.4138 2.685.4616 4.0274-.016.9161-.3342 1.7058-.8754 2.4165-1.528.1263-.1274.2527-.1751.379-.2547zm-1.7057-1.3211c-2.0848 1.5758-5.1488.9073-6.4438-1.3689-1.153-2.0374-.9635-4.5205.5369-6.30323.9477-1.13012 2.227-1.49622 3.6484-1.41663 1.8163.0955 3.3641 1.30521 3.9169 3.05606.1105.3661.2211.7322.2842 1.1143.0474.2546.0316.5252.0316.6685.0158 1.8305-.6317 3.2312-1.9742 4.2499z" />
        <path d="m136 1.08236c.016-.33426-.111-.493432-.458-.477515-.584.015917-1.153 0-1.737 0-.569 0-.585.015917-.585.604855v6.39873c0 .14325-.031.30242-.063.5571-.332-.30243-.584-.52527-.837-.74811-.758-.68444-1.611-1.19379-2.622-1.40072-1.105-.23876-2.195-.17509-3.269.14326-2.164.63669-3.68 2.0374-4.517 4.10664-.79 1.9737-.837 4.0111-.079 6.0167.963 2.5786 2.811 4.1544 5.512 4.6001 1.943.3183 3.664-.2547 5.133-1.5758.189-.1751.395-.3343.584-.5094.048.016.095.0319.142.0637 0 .382.016.764 0 1.1301-.015.3661.079.5412.49.5094.537-.0319 1.074 0 1.611 0 .679 0 .679-.0159.679-.7004 0-5.953 0-11.90606 0-17.85911.016-.27059 0-.5571.016-.85953zm-3.712 15.61484c-1.089 1.3848-2.558 1.8941-4.28 1.6235-2.148-.3183-3.632-2.1647-3.759-4.5205-.11-2.0055.522-3.6769 2.29-4.77514.68-.41385 1.438-.58894 2.054-.5571 2.179-.04776 3.838 1.14604 4.485 3.11974.6 1.8464.411 3.5814-.79 5.1095z" />
        <path d="m102.501 6.9558c-1.547-.97095-3.2216-1.24154-4.9905-.95503-2.0058.33426-3.5694 1.36888-4.6434 3.10386-1.2477 2.03737-1.4372 4.23397-.8213 6.51017.8055 2.9606 3.0482 4.8547 5.8911 5.2686 2.3851.3502 4.5491-.2388 6.2541-2.0693.537-.573 1.011-1.2097 1.295-1.9737-.094-.0318-.158-.0796-.205-.0796-.9 0-1.785-.0159-2.685 0-.142 0-.316.1274-.426.2388-1.232 1.4644-3.1907 1.7668-4.8964.9868-1.3267-.6207-2.2269-1.6235-2.5586-3.0879-.1263-.5412-.079-.5571.4738-.5571h9.7922c.521 0 .584-.0637.632-.5889.079-1.1461-.048-2.2762-.458-3.3586-.521-1.41661-1.343-2.62632-2.654-3.4381zm-.316 4.9184c-1.168 0-2.337 0-3.5058 0-1.1687 0-2.3374 0-3.5062 0-.3948 0-.4896-.0955-.3632-.4775.6949-2.16474 2.5112-3.32669 4.8329-2.83326 1.5003.31835 2.4323 1.3848 2.9063 2.84916.11.3502-.032.4775-.364.4616z" />
        <path d="m75.7786.604736c-3.3325 0-6.665 0-9.9817 0-.5843 0-.6001.031835-.6001.604854v9.31161 9.3115c0 .6208.0316.6686.6317.6686h1.6584c.6001 0 .6159-.0319.6159-.6367 0-2.4672 0-4.9344.0158-7.4015 0-.5571.0158-.5571.5528-.5571h6.7123c.5212 0 .5686-.0637.5686-.5571 0-.4457 0-.9073 0-1.353 0-.84361 0-.84361-.8528-.84361-2.1638 0-4.3117 0-6.4755 0-.4896 0-.5212-.04775-.5212-.52527 0-1.57581 0-3.15161.0158-4.72742 0-.5571.0158-.5571.5528-.5571h7.1072c.4896 0 .5212-.03184.537-.50935 0-.57302 0-1.14604 0-1.70315 0-.461595-.0474-.509346-.537-.525264z" />
        <path d="m120.048 11.0625c-.331-2.41944-1.484-4.17034-3.901-4.93437-1.895-.58893-3.727-.07958-5.022 1.43255-.174.19101-.332.39793-.569.68444-.015-.23875-.047-.36609-.047-.47751 0-.31835-.016-.63669 0-.95504.016-.33426-.095-.47751-.442-.4616-.585.01592-1.185 0-1.769 0-.537 0-.553.01592-.553.55711v7.27422 5.762c0 .4934.047.5412.537.5412h1.69c.521 0 .537-.0159.537-.5412 0-2.0533-.016-4.1067 0-6.16 0-.7163.063-1.4325.158-2.1329.189-1.3848.963-2.76959 2.78-3.10385 1.232-.22284 2.511.04775 3.269 1.2893.458.76405.6 1.59175.6 2.46715v7.7199c0 .3342.079.4775.442.4616.616-.0319 1.232 0 1.848-.0159.458 0 .521-.0637.521-.5412 0-2.5786 0-5.1413 0-7.7199-.015-.3661-.031-.764-.079-1.146z" />
        <path d="m85.3653 8.56358c0-.60485 0-1.22562 0-1.83048 0-.27059-.1105-.44568-.379-.47751-.9792-.12734-1.9584-.14326-2.7955.49343-.5054.38201-.9634.81178-1.4372 1.20971-.0474 0-.0948.01592-.1264.01592 0-.3661-.0158-.74811 0-1.11421.0158-.38201-.1106-.52527-.4896-.50935-.6002.01592-1.2161 0-1.8163 0-.4896 0-.5054.01592-.5054.50935v7.00356 6.1282c0 .4775.0474.5093.5212.5252h1.7373c.5212 0 .5528-.0477.5528-.573 0-2.2443-.0158-4.4727.0158-6.7171.0158-.7162.1106-1.4484.2843-2.1488.2685-1.09828 1.1529-2.19657 2.4954-2.22841.5054-.01591 1.0108.04776 1.532.07959.2685.01592.4106-.06367.4106-.3661z" />
        <path d="m89.4875 6.35107c-.6159.01592-1.2319 0-1.8478 0-.5054 0-.5054 0-.5054.50935v6.55788 6.5261c0 .5571.0158.573.5843.573h1.5794c.616 0 .6318-.0318.6318-.6526 0-4.0111 0-8.0223 0-12.04934 0-.35018 0-.68444 0-1.03462 0-.30243-.1422-.42977-.4423-.42977z" />
        <path d="m87.3076 3.69282c.4107-.01591.8213 0 1.2161 0h1.2635c.3001 0 .4265-.12733.4265-.42976-.0158-.77995-.0158-1.55989 0-2.339834 0-.302428-.1264-.429766-.4265-.413848-.8212.015917-1.6583.015917-2.4796 0-.3159 0-.458.127338-.458.445682.0158.77994.0158 1.55989.0158 2.33983 0 .28651.1421.39793.4422.39793z" />
        <path d="m107.381 33.9991c-.173-.2069-.173-.3502.016-.5412.3-.2865.569-.5889.853-.8913.474-.4776.948-.971 1.406-1.4485.142-.1433.221-.3343-.063-.3661-.601-.0478-1.169-.1114-1.643.4934-.537.6845-1.185 1.2734-1.785 1.8942-.079.0796-.189.0955-.284.1432-.032-.1114-.095-.2069-.095-.3183-.016-.4775 0-.955 0-1.4326 0-.8436 0-.8277-.837-.8117-.268 0-.363.1114-.363.382v6.1281c0 .0955.047.2865.079.2865.316.0319.647.0637.963.0159.079-.0159.142-.3024.142-.4775.016-.573-.031-1.146.032-1.7031.032-.2388.269-.4457.411-.6686.158.1115.347.1911.474.3343.584.6845 1.137 1.3848 1.705 2.0852.379.4775 1.169.6526 1.801.3502-.079-.1274-.142-.2229-.221-.3184-.869-1.0187-1.722-2.0852-2.591-3.1357z" />
        <path d="m134.246 32.7258c-.774-.2387-1.468 0-2.1.4616-.063-.0796-.111-.1114-.127-.1432-.205-.4457-.6-.2388-.9-.2229-.095 0-.221.3184-.221.4934-.016 2.0534-.016 4.1067-.016 6.16 0 .4457.063.4775.6.4934.364.016.537-.1273.522-.5093-.016-.573-.016-1.146 0-1.7031 0-.1592.047-.3184.079-.4935 1.611.764 2.463.6526 3.316-.382.679-.8277.695-2.3876.048-3.2789-.316-.4298-.711-.7163-1.201-.8755zm-.852 4.0589c-.79.0159-1.453-.7163-1.453-1.5599.015-.8595.615-1.544 1.374-1.5599.852-.0159 1.421.6049 1.421 1.5599 0 .8755-.584 1.544-1.342 1.5599z" />
        <path d="m92.914 33.076c-.0631.0478-.1263.0796-.2843.1751-.0316-.2069-.0631-.3502-.0631-.4775-.0158-.4934 0-.9869-.0158-1.4644-.0158-.4934-.0632-.5253-.616-.5253-.458 0-.5212.0478-.5212.5253v4.5364c0 .4616-.0158.9232.0158 1.3848.0158.1274.1422.3502.2369.3661.3791.0478.8055.1751.995-.3661.079.0478.1422.0796.1896.1274.8054.6048 1.9268.5889 2.7007-.0478.8844-.7322 1.0582-1.7031.8371-2.7696-.3159-1.6872-2.1006-2.4035-3.4747-1.4644zm1.0108 3.7087c-.7739 0-1.4372-.7162-1.4214-1.5439 0-.8596.6317-1.5758 1.4056-1.5758.8213 0 1.4057.6526 1.4057 1.5917-.0158.8595-.616 1.528-1.3899 1.528z" />
        <path d="m124.833 32.7259c-.284.0955-.552.2865-.9.4616-.016-.2228-.031-.3661-.031-.5252 0-.4616 0-.9232 0-1.3848s-.064-.5253-.537-.5253c-.585 0-.585 0-.585.5093v5.8417c0 .3342.048.5253.443.5093.631-.0318.663-.0318.679-.5412.015-.7481 0-1.4803.031-2.2284.016-.4775.19-.8913.664-1.0983.726-.3183 1.389.0637 1.453.8755.047.8118.015 1.6235.031 2.4194 0 .5094.032.5412.537.5412.111 0 .237.0478.316 0 .111-.0637.284-.1751.284-.2706 0-1.0665.032-2.1329-.079-3.1834-.142-1.0983-1.247-1.7509-2.306-1.4008z" />
        <path d="m115.215 32.7577c-.237.0955-.458.2388-.711.382-.221-.3342-.584-.4616-.995-.3342-.347.0955-.221.4138-.221.6367-.016 1.2256 0 2.4353 0 3.6609 0 .4935.032.5094.6.5253.49 0 .521-.0318.521-.5094 0-.7481 0-1.5121.016-2.2602.016-.4616.205-.8595.632-1.0665.774-.3501 1.437.0955 1.469.9551.031.764.016 1.544.016 2.308 0 .5571.015.573.584.573.11 0 .221.0159.316-.0159.095-.0478.237-.1751.237-.2547 0-1.0665.047-2.1488-.063-3.2153-.127-1.1619-1.343-1.7986-2.401-1.3848z" />
        <path d="m100.906 32.7895c-.648-.0318-.695.0159-.869.6526-.0632.207-.1263.398-.2053.6049-.2211.6367-.4264 1.2574-.6791 2.0056-.1106-.207-.1896-.3343-.2211-.4616-.2685-.7959-.5212-1.5759-.8055-2.3717-.0632-.1592-.1895-.3661-.3317-.3979-.2843-.0637-.5844-.016-.9476-.016.0632.207.079.3025.1105.398.4739 1.337.9635 2.6581 1.4215 3.9952.0789.2228.0632.5253 0 .7481-.158.5571-.3949 1.0983-.5844 1.6395-.0316.0955.0158.2865.079.3183.3474.2069.9002-.0477 1.0424-.4457.4422-1.1938.8686-2.3875 1.3113-3.5654.331-.8914.647-1.7827.979-2.69.142-.3502.095-.398-.3-.4139z" />
        <path d="m121.296 35.0656c-.379-.2229-.822-.3502-1.217-.5731-.189-.1114-.347-.3342-.426-.5411-.032-.0955.158-.3343.3-.398.253-.1273.553-.0796.695.191.205.3502.505.3502.853.3502.3-.0159.363-.1592.268-.4138-.221-.589-.663-.8914-1.263-1.0028-1.153-.2229-2.085.5412-1.943 1.5917.095.6526.553.955 1.09 1.1779.316.1273.632.2547.932.4297.253.1592.347.4457.205.7322-.142.2706-.41.4139-.695.3025-.221-.0796-.41-.2706-.568-.4457-.174-.191-.758-.3661-.964-.191-.268.2069-.063.4616.032.6685.331.6526.9.8436 1.579.8436.158-.0159.316-.0159.474-.0318.648-.0796 1.106-.4935 1.264-1.1779.142-.573-.079-1.1938-.616-1.5121z" />
        <path d="m128.987 32.7736c-.3 0-.584-.0477-.584.4139.016.6685 0 1.3529 0 2.0215v1.6713c0 .764-.111.7162.774.7162.268 0 .41-.0955.41-.3979 0-1.337 0-2.6741 0-4.0111 0-.4298-.3-.4139-.6-.4139z" />
        <path d="m111.93 35.2249c0-.6685 0-1.3529 0-2.0215 0-.2865-.111-.4297-.395-.4138-.126 0-.268 0-.395 0-.237-.0159-.331.0955-.331.3342 0 1.4008 0 2.8015.015 4.2022 0 .0955.158.2228.285.2865.11.0478.252 0 .395.0159.315.0159.458-.1114.442-.4457-.016-.6685-.016-1.3052-.016-1.9578z" />
        <path d="m129.019 30.6248c-.664 0-.664 0-.664.6526 0 .4934.048.5093.68.5093.568 0 .568 0 .552-.7003.016-.4616.016-.4616-.568-.4616z" />
        <path d="m111.393 30.6248c-.616 0-.616 0-.616.5252 0 .6049.016.6367.568.6367.648 0 .648 0 .648-.573 0-.5889 0-.5889-.6-.5889z" />
        <path d="m137.014.626465v.267748h.47v1.206247h.295v-1.206247h.008.461v-.267748z" />
        <path d="m139.688.626465s-.475.999225-.498 1.073755h-.011c-.023-.07453-.498-1.073755-.498-1.073755h-.312v1.473995h.321l-.014-.86949c.18.37816.382.86949.382.86949h.253s.202-.49133.382-.86949l-.014.86949h.321v-1.473995z" />
        <path d="m137.014 31v.2677h.47v1.2063h.295v-1.2063h.008.461v-.2677z" />
        <path d="m139.688 31s-.475.9992-.498 1.0738h-.011c-.023-.0746-.498-1.0738-.498-1.0738h-.312v1.474h.321l-.014-.8695c.18.3782.382.8695.382.8695h.253s.202-.4913.382-.8695l-.014.8695h.321v-1.474z" />
      </g>
    </svg>
  );
}
