import React from "react";

export default function FacebookLogo() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65222 11.0315H7.47445C6.85929 11.0315 6.24412 11.0319 5.62895 11.0311C5.32772 11.0306 5.21081 10.9161 5.21037 10.6194C5.2095 9.80211 5.2095 8.98524 5.21037 8.16751C5.21081 7.8687 5.33428 7.74935 5.64033 7.74892C6.25112 7.74849 6.86148 7.74892 7.47227 7.74892H7.65222V7.60362C7.65222 7.06611 7.65222 6.52859 7.65222 5.99108C7.65222 5.1967 7.79583 4.43562 8.2039 3.73941C8.62204 3.02589 9.23064 2.53724 10.0109 2.254C10.5105 2.07281 11.0297 2.00016 11.56 2.00016C12.2237 1.99973 12.8875 2.00016 13.5513 2.00146C13.8381 2.00189 13.965 2.12514 13.9659 2.40838C13.9672 3.16816 13.9668 3.92881 13.9659 4.68946C13.9655 4.97659 13.8442 5.09378 13.5543 5.09638C13.0105 5.10243 12.4663 5.09854 11.9225 5.12016C11.6283 5.13141 11.345 5.21789 11.2018 5.50676C11.1339 5.64384 11.0858 5.80514 11.0818 5.95735C11.0687 6.54632 11.0766 7.13616 11.0766 7.74892H11.2281C11.9628 7.74892 12.6975 7.74849 13.4317 7.74892C13.7457 7.74892 13.8656 7.86697 13.8656 8.17573C13.8661 8.98827 13.8656 9.80081 13.8652 10.6138C13.8648 10.9212 13.7535 11.0311 13.4401 11.0315C12.7058 11.0319 11.9711 11.0315 11.2368 11.0315H11.0709V11.2045V17.537C11.0709 17.8895 10.9614 17.9967 10.6028 17.9967H8.09356C7.7665 17.9967 7.65222 17.886 7.65222 17.5656C7.65222 15.451 7.65222 13.3355 7.65222 11.2205V11.0315Z"
        fill="#192617"
      />
    </svg>
  );
}
