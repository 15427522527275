import React from "react";
import PropTypes from "prop-types";

export default function SmallMammalIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2002 10.2002C6.47634 10.2002 6.7002 9.97635 6.7002 9.7002C6.7002 9.42406 6.47634 9.2002 6.2002 9.2002C5.92405 9.2002 5.7002 9.42406 5.7002 9.7002C5.7002 9.97635 5.92405 10.2002 6.2002 10.2002Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M14 10.2002C14.2761 10.2002 14.5 9.97635 14.5 9.7002C14.5 9.42406 14.2761 9.2002 14 9.2002C13.7239 9.2002 13.5 9.42406 13.5 9.7002C13.5 9.97635 13.7239 10.2002 14 10.2002Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.4001 12.3C11.4001 11.5 10.8001 11.5 10.0001 11.5C9.2001 11.5 8.6001 11.6 8.6001 12.3C8.6001 13 10.0001 13.1 10.0001 13.1C10.0001 13.1 11.4001 13 11.4001 12.3Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.2 6.89973C18.3 6.39973 18.8 5.29973 18.8 3.99973C18.8 2.69973 18.1 1.39973 16.9 0.899725C15 0.0997254 13.2 1.09973 12.5 2.69973C11.7 2.19973 10.9 1.89973 10 1.89973C9.1 1.89973 8.3 2.19973 7.5 2.69973C6.9 1.09973 5.1 0.199725 3.2 0.899725C2 1.39973 1.2 2.69973 1.2 3.99973C1.2 5.29973 1.8 6.49973 2.9 6.89973C3.1 6.99973 3.2 7.29973 2.9 7.39973C1.6 8.19973 1 9.59973 1 11.1997C1 13.6997 3.2 15.6997 5.9 15.6997C7.5 15.6997 8.9 14.9997 9.8 13.8997C10 13.6997 10.3 13.6997 10.5 13.8997C11.4 14.9997 12.7 15.6997 14.2 15.6997C16.8 15.6997 18.9 13.6997 18.9 11.1997C18.9 9.59973 18.3 8.19973 17 7.39973C16.9 7.29973 17 6.99973 17.2 6.89973Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1998 15.3998V17.3998H7.7998V15.2998"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

SmallMammalIcon.propTypes = {
  color: PropTypes.string,
};

SmallMammalIcon.defaultProps = {
  color: "",
};
