import React from "react";
import PropTypes from "prop-types";

export default function Clock({ className, color }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.69231 17.3846C13.9407 17.3846 17.3846 13.9407 17.3846 9.69231C17.3846 5.44396 13.9407 2 9.69231 2C5.44396 2 2 5.44396 2 9.69231C2 13.9407 5.44396 17.3846 9.69231 17.3846Z"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M9 7V10.375H12"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Clock.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Clock.defaultProps = {
  className: "",
  color: "",
};
