import React from "react";
import PropTypes from "prop-types";

export default function Magglass({ className, color }) {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12837 4.01825C8.57259 1.57403 12.5355 1.57403 14.9797 4.01825C17.4239 6.46247 17.4239 10.4253 14.9797 12.8696C12.5355 15.3138 8.57259 15.3138 6.12837 12.8696C3.68415 10.4253 3.68415 6.46247 6.12837 4.01825ZM16.1111 2.88688C13.042 -0.182182 8.06606 -0.182182 4.997 2.88688C2.1189 5.76498 1.93982 10.32 4.45975 13.4068L0.304627 17.5619C-0.00779228 17.8744 -0.00779243 18.3809 0.304627 18.6933C0.617046 19.0057 1.12358 19.0057 1.436 18.6933L5.59112 14.5382C8.67791 17.0581 13.233 16.879 16.1111 14.0009C19.1801 10.9319 19.1801 5.95594 16.1111 2.88688Z"
        fill={color || "#9B9694"}
      />
    </svg>
  );
}

Magglass.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Magglass.defaultProps = {
  className: "",
  color: "",
};
