import React from "react";
import PropTypes from "prop-types";

export default function BirdIcon({ color = "#000" }) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.69971 10.7998C9.97585 10.7998 10.1997 10.5759 10.1997 10.2998C10.1997 10.0237 9.97585 9.7998 9.69971 9.7998C9.42357 9.7998 9.19971 10.0237 9.19971 10.2998C9.19971 10.5759 9.42357 10.7998 9.69971 10.7998Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M0.799767 18.6002C0.799767 18.6002 0.499765 12.2002 4.39977 9.0002C4.79977 8.7002 5.09976 8.4002 5.59976 8.2002"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.3999 18.9998V8.19984C12.3999 8.19984 12.7999 4.99984 8.79991 5.79984C4.79991 6.59984 1.7999 1.29984 1.5999 0.999841C1.2999 0.399841 -0.600097 8.39983 3.4999 9.89983"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.3999 8.49972C12.3999 8.49972 17.1999 7.39972 17.1999 13.7997H12.3999"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.5999 13.7998C15.5999 13.7998 15.5999 16.2998 12.3999 15.7998"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

BirdIcon.propTypes = {
  color: PropTypes.string,
};

BirdIcon.defaultProps = {
  color: "",
};
