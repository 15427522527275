import React from "react";
import PropTypes from "prop-types";

export default function Close({ color, width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7.5L22.7143 24.2143"
        stroke={color || "#27272F"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.7143 7.5L6 24.2143"
        stroke={color || "#27272F"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Close.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Close.defaultProps = {
  color: "#27272F",
  width: 30,
  height: 30,
};
