import React from "react";
import PropTypes from "prop-types";

export default function Email(props) {
  const { color } = props;
  return (
    <svg
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={color || "#27272F"}
        strokeLinecap="round"
        strokeWidth="2"
      >
        <rect
          height="54.9"
          rx="8"
          width="82.8"
          x="4"
          y="18"
        />
        <path d="m10 18 32.6014 10.2461c1.5614.4908 3.2358.4908 4.7972.0001l32.6014-10.2462" />
        <path d="m58.5264 57c-8.4158-2.73-17.4791-2.73-25.8948 0" />
      </g>
      <g fill="#ff7817">
        <ellipse
          cx="27.2368"
          cy="42.15"
          rx="3.23684"
          ry="3.15"
        />
        <ellipse
          cx="61.7632"
          cy="42.15"
          rx="3.23684"
          ry="3.15"
        />
      </g>
    </svg>
  );
}

Email.propTypes = {
  color: PropTypes.string,
};

Email.defaultProps = {
  color: "",
};
