import React from "react";
import PropTypes from "prop-types";

export default function MiniCheck(props) {
  const { color } = props;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8L5.64706 10.5L12 4.5"
        stroke={color || "#54C052"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

MiniCheck.propTypes = {
  color: PropTypes.string,
};

MiniCheck.defaultProps = {
  color: "",
};
