import React from "react";
import PropTypes from "prop-types";

export default function CatIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.30029 11.0996C6.57643 11.0996 6.80029 10.8758 6.80029 10.5996C6.80029 10.3235 6.57643 10.0996 6.30029 10.0996C6.02414 10.0996 5.80029 10.3235 5.80029 10.5996C5.80029 10.8758 6.02414 11.0996 6.30029 11.0996Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9004 11.0996C14.1765 11.0996 14.4004 10.8758 14.4004 10.5996C14.4004 10.3235 14.1765 10.0996 13.9004 10.0996C13.6243 10.0996 13.4004 10.3235 13.4004 10.5996C13.4004 10.8758 13.6243 11.0996 13.9004 11.0996Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9001 14.3002C11.9001 13.3002 11.1001 13.2002 10.0001 13.2002C8.9001 13.2002 8.1001 13.3002 8.1001 14.3002C8.1001 15.3002 10.0001 15.4002 10.0001 15.4002C10.0001 15.4002 11.9001 15.3002 11.9001 14.3002Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 18.6004V15.4004"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.2001 7.50004L18.5001 1.90004C18.5001 1.60004 18.2001 1.40004 18.0001 1.60004L14.4001 3.70004C13.0001 3.00004 11.5001 2.60004 10.0001 2.60004C8.5001 2.60004 6.9001 3.00004 5.6001 3.80004L2.0001 1.60004C1.8001 1.50004 1.4001 1.60004 1.5001 1.90004L1.8001 7.70004C1.3001 8.60004 1.1001 9.70004 1.1001 10.7C1.1001 15.2 5.6001 18.8 10.1001 18.8C14.6001 18.8 19.1001 15.2 19.1001 10.7C19.0001 9.50004 18.7001 8.50004 18.2001 7.50004Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

CatIcon.propTypes = {
  color: PropTypes.string,
};

CatIcon.defaultProps = {
  color: "",
};
