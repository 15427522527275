import React from "react";
import PropTypes from "prop-types";

export default function Cat(props) {
  const { color, size } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.248 10.744a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm7.6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
        fill="#192617"
        stroke={color}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
      <path
        d="M11.847 13.944c0-1-.8-1.1-1.9-1.1-1.1 0-1.9.1-1.9 1.1s1.9 1.1 1.9 1.1 1.9-.1 1.9-1.1Zm-1.9 4.3v-3.2"
        stroke={color}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
      <path
        d="m18.147 7.144.3-5.6c0-.3-.3-.5-.5-.3l-3.6 2.1c-1.4-.7-2.9-1.1-4.4-1.1-1.5 0-3.1.4-4.4 1.2l-3.6-2.2c-.2-.1-.6 0-.5.3l.3 5.8c-.5.9-.7 2-.7 3 0 4.5 4.5 8.1 9 8.1s9-3.6 9-8.1c-.1-1.2-.4-2.2-.9-3.2Z"
        stroke={color}
        strokeWidth={1.2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

Cat.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Cat.defaultProps = {
  color: "#192617",
  size: 20,
};
