import React from "react";
import PropTypes from "prop-types";

export default function Calendar({ className, color }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.66666"
        y="4.07611"
        width="16.6667"
        height="13.0797"
        rx="2"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M5.83334 1.66669V6.48553"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M14.1667 1.66669V6.48553"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M1.66666 9.2391H18.3333"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Calendar.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Calendar.defaultProps = {
  className: "",
  color: "",
};
