import React from "react";
import PropTypes from "prop-types";

export default function Notification(props) {
  const { color } = props;
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5195 9.66425C15.5195 10.3364 15.5048 11.01 15.5293 11.6815C15.5356 11.8571 15.6203 12.0654 15.7407 12.1952C16.167 12.6556 16.6248 13.0881 17.0686 13.5321C17.3136 13.7773 17.462 14.6565 17.2562 14.925C17.1624 15.0473 16.9391 15.1333 16.7732 15.1354C15.5111 15.1525 5.22032 15.1449 3.97991 15.1443C3.49621 15.1443 3.40101 15.0254 3.36181 14.5629C3.31561 14.028 3.45841 13.6079 3.89311 13.2527C4.27391 12.9419 4.61272 12.5791 4.93822 12.2116C5.06352 12.0709 5.16432 11.8571 5.16712 11.6747C5.18812 10.402 5.14892 9.12868 5.19092 7.85671C5.23292 6.57039 5.77963 5.46647 6.73234 4.59002C8.00425 3.41915 9.53026 2.92798 11.2649 3.23676C13.6246 3.65619 15.4943 5.64408 15.5188 8.05072C15.5237 8.58833 15.5195 9.12663 15.5195 9.66425Z"
        stroke={color || "#9B9694"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M7.35352 15.1465C7.35352 16.8033 8.69666 18.1465 10.3535 18.1465C12.0104 18.1465 13.3535 16.8033 13.3535 15.1465"
        stroke={color || "#9B9694"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Notification.propTypes = {
  color: PropTypes.string,
};

Notification.defaultProps = {
  color: "",
};
