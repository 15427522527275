import React from "react";
import PropTypes from "prop-types";

export default function ReptileIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2002 4.30078C6.47634 4.30078 6.7002 4.07691 6.7002 3.80077C6.7002 3.52463 6.47634 3.30078 6.2002 3.30078C5.92405 3.30078 5.7002 3.52463 5.7002 3.80077C5.7002 4.07691 5.92405 4.30078 6.2002 4.30078Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.5 4.30078C13.7761 4.30078 14 4.07691 14 3.80077C14 3.52463 13.7761 3.30078 13.5 3.30078C13.2239 3.30078 13 3.52463 13 3.80077C13 4.07691 13.2239 4.30078 13.5 4.30078Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.7002 5.30047C16.9002 4.70047 17.0002 4.00046 16.8002 3.20046C16.4002 1.90046 15.3002 0.900462 14.0002 0.700462C12.2002 0.400462 10.6002 1.60046 10.2002 3.20046H10.1002H10.0002C9.6002 1.60046 8.0002 0.400462 6.2002 0.700462C4.9002 0.900462 3.8002 1.90046 3.4002 3.20046C3.2002 3.90046 3.2002 4.60047 3.5002 5.30047C2.1002 6.40047 1.2002 7.80047 1.2002 9.30047C1.2002 9.60047 1.2002 10.0005 1.3002 10.3005C2.0002 13.2005 5.7002 15.3005 10.2002 15.3005C15.2002 15.3005 19.2002 12.6005 19.2002 9.30047C19.0002 7.70047 18.1002 6.30047 16.7002 5.30047Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.1 9H3"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.6004 9V11C12.6004 12.4 11.4004 13.6 10.0004 13.6C8.60039 13.6 7.40039 12.4 7.40039 11V9"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10 9V10.6"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

ReptileIcon.propTypes = {
  color: PropTypes.string,
};

ReptileIcon.defaultProps = {
  color: "",
};
