import React from "react";
import PropTypes from "prop-types";

export default function DogIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3997 17.0002H7.69974C4.29974 17.0002 1.79975 14.4002 2.39975 11.5002L4.69974 5.0002C5.19974 2.8002 7.39974 1.2002 10.0997 1.2002C12.6997 1.2002 14.9997 2.8002 15.4997 5.0002L17.7997 11.6002C18.2997 14.4002 15.7997 17.0002 12.3997 17.0002Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M6.5998 1.8C6.5998 1.8 5.4998 0 3.6998 1.8C1.8998 3.6 0.799805 6.8 0.799805 8.6C0.799805 10.6 2.6998 10.5 2.6998 10.5"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3999 1.8C13.3999 1.8 14.4999 0 16.2999 1.8C18.0999 3.6 19.1999 6.8 19.1999 8.6C19.1999 10.6 17.2999 10.5 17.2999 10.5"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.09961 8.40039C8.37575 8.40039 8.59961 8.17653 8.59961 7.90039C8.59961 7.62425 8.37575 7.40039 8.09961 7.40039C7.82347 7.40039 7.59961 7.62425 7.59961 7.90039C7.59961 8.17653 7.82347 8.40039 8.09961 8.40039Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1997 8.40039C12.4758 8.40039 12.6997 8.17653 12.6997 7.90039C12.6997 7.62425 12.4758 7.40039 12.1997 7.40039C11.9236 7.40039 11.6997 7.62425 11.6997 7.90039C11.6997 8.17653 11.9236 8.40039 12.1997 8.40039Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.8998 11.8998C11.8998 10.8998 11.0998 10.7998 10.0998 10.7998C9.0998 10.7998 8.2998 10.8998 8.2998 11.8998C8.2998 12.8998 9.9998 12.9998 9.9998 12.9998C9.9998 12.9998 11.8998 12.8998 11.8998 11.8998Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 17V13"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

DogIcon.propTypes = {
  color: PropTypes.string,
};

DogIcon.defaultProps = {
  color: "",
};
