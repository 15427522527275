import React from "react";
import PropTypes from "prop-types";

export default function Bank(props) {
  const { color } = props;
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 84C20 85.6569 21.3431 87 23 87H31C32.6569 87 34 85.6569 34 84V58C34 56.3431 32.6569 55 31 55H23C21.3431 55 20 56.3431 20 58V84Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 84C43 85.6569 44.3431 87 46 87H54C55.6569 87 57 85.6569 57 84V58C57 56.3431 55.6569 55 54 55H46C44.3431 55 43 56.3431 43 58V84Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 84C66 85.6569 67.3431 87 69 87H77C78.6569 87 80 85.6569 80 84V58C80 56.3431 78.6569 55 77 55H69C67.3431 55 66 56.3431 66 58V84Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 45C11 46.6569 12.3431 48 14 48H86C87.6569 48 89 46.6569 89 45V38C89 36.3431 87.6569 35 86 35H14C12.3431 35 11 36.3431 11 38V45Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20 35L48.7094 10.6463C49.8289 9.69658 51.4713 9.69659 52.5908 10.6463L81 34.7458"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 23.5C54 21.567 52.433 20 50.5 20C48.567 20 47 21.567 47 23.5C47 25.433 48.567 27 50.5 27C52.433 27 54 25.433 54 23.5Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Bank.propTypes = {
  color: PropTypes.string,
};

Bank.defaultProps = {
  color: "",
};
