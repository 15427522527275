import React from "react";
import PropTypes from "prop-types";

export default function Eye(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M19 9.75C19 11 14.9706 16.5 10 16.5C5.02944 16.5 1 11 1 9.75C1 9 5.02944 3 10 3C14.9706 3 19 9 19 9.75Z"
          stroke={color || "#40493D"}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <circle
          cx="10"
          cy="9.75"
          r="2.8125"
          stroke={color || "#40493D"}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

Eye.propTypes = {
  color: PropTypes.string,
};

Eye.defaultProps = {
  color: "",
};
