import React from "react";
import PropTypes from "prop-types";

export default function FishIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 10C14.7761 10 15 9.77614 15 9.5C15 9.22386 14.7761 9 14.5 9C14.2239 9 14 9.22386 14 9.5C14 9.77614 14.2239 10 14.5 10Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.71998 11.6201C0.949978 12.6401 0.819978 14.6701 0.999978 14.9101C1.23998 15.2301 2.70998 14.9901 3.48998 13.9601C4.02998 13.2401 4.27998 12.0801 4.29998 11.3301C4.73998 12.2401 5.80998 13.4401 7.16998 14.4101C6.45998 15.0301 6.06998 16.4501 6.16998 16.6801C6.30998 17.0001 7.46998 17.2101 8.22998 16.6401C8.57998 16.3701 8.85998 15.9201 9.04998 15.4801C9.86998 15.8301 10.73 16.0501 11.57 16.0501C15.19 16.0501 19.06 12.0801 19.06 10.5701C19.06 9.45013 15.19 5.10013 11.57 5.10013C11.47 5.10013 11.36 5.11013 11.26 5.12013C10.87 4.38013 10.08 3.36013 9.19998 3.08013C7.90998 2.68013 6.23998 3.80013 6.16998 4.19013C6.08998 4.61013 6.74998 5.80013 7.64998 6.51013C6.07998 7.57013 4.80998 9.00013 4.30998 9.91013C4.30998 9.18013 4.08998 7.90013 3.51998 7.15013C2.74998 6.13013 1.21998 5.96013 1.03998 6.20013C0.799978 6.52013 0.979978 8.47013 1.75998 9.49013C2.42998 10.3801 2.40998 10.7201 1.72998 11.6201H1.71998Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.5301 15.6898C12.7201 15.6898 12.0601 13.4098 12.0601 10.5998C12.0601 7.78977 12.7201 5.50977 13.5301 5.50977"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

FishIcon.propTypes = {
  color: PropTypes.string,
};

FishIcon.defaultProps = {
  color: "",
};
