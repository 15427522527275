import React from "react";
import PropTypes from "prop-types";

export default function Wrench({ color }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2501 5.25043C11.0974 5.40621 11.0119 5.61564 11.0119 5.83376C11.0119 6.05189 11.0974 6.26132 11.2501 6.4171L12.5835 7.75043C12.7392 7.90312 12.9487 7.98865 13.1668 7.98865C13.3849 7.98865 13.5943 7.90312 13.7501 7.75043L16.8918 4.60876C17.3108 5.53476 17.4377 6.56646 17.2555 7.56639C17.0733 8.56631 16.5907 9.48697 15.872 10.2057C15.1533 10.9244 14.2327 11.407 13.2327 11.5891C12.2328 11.7713 11.2011 11.6445 10.2751 11.2254L4.51679 16.9838C4.18527 17.3153 3.73563 17.5015 3.26679 17.5015C2.79795 17.5015 2.34831 17.3153 2.01679 16.9838C1.68527 16.6522 1.49902 16.2026 1.49902 15.7338C1.49902 15.2649 1.68527 14.8153 2.01679 14.4838L7.77512 8.72543C7.35609 7.79944 7.22922 6.76774 7.41141 5.76781C7.5936 4.76788 8.0762 3.84723 8.79489 3.12853C9.51359 2.40984 10.4342 1.92724 11.4342 1.74505C12.4341 1.56286 13.4658 1.68973 14.3918 2.10876L11.2585 5.2421L11.2501 5.25043Z"
        stroke={color || "#192617"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Wrench.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  color: PropTypes.string,
};

Wrench.defaultProps = {
  color: "",
};
