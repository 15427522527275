import React from "react";
import PropTypes from "prop-types";

export default function RabbitIcon({ color = "#000" }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7998 11.6992C7.07595 11.6992 7.2998 11.4754 7.2998 11.1992C7.2998 10.9231 7.07595 10.6992 6.7998 10.6992C6.52366 10.6992 6.2998 10.9231 6.2998 11.1992C6.2998 11.4754 6.52366 11.6992 6.7998 11.6992Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2002 11.6992C13.4763 11.6992 13.7002 11.4754 13.7002 11.1992C13.7002 10.9231 13.4763 10.6992 13.2002 10.6992C12.9241 10.6992 12.7002 10.9231 12.7002 11.1992C12.7002 11.4754 12.9241 11.6992 13.2002 11.6992Z"
        fill={color}
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 14.5994C12 13.4994 11.1 13.3994 10 13.3994C8.9 13.3994 8 13.4994 8 14.5994C8 15.6994 10 15.7994 10 15.7994C10 15.7994 12 15.6994 12 14.5994Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 19.2998V15.7998"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.8 9.39961V2.49961C16.8 1.39961 15.9 0.599609 14.9 0.599609C13.9 0.599609 13 1.49961 13 2.49961V6.39961C12.1 6.09961 11.1 5.89961 10.1 5.89961C9.1 5.89961 8.1 6.09961 7.2 6.39961V2.49961C7.2 1.39961 6.3 0.599609 5.3 0.599609C4.3 0.599609 3.4 1.49961 3.4 2.49961V9.39961C2.8 10.3996 1.5 11.4996 1.5 12.6996C1.5 16.4996 6 19.4996 10.3 19.4996C14.6 19.4996 19.1 16.3996 19.1 12.6996C18.8 11.4996 17.4 10.3996 16.8 9.39961Z"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

RabbitIcon.propTypes = {
  color: PropTypes.string,
};

RabbitIcon.defaultProps = {
  color: "",
};
