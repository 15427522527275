import React from "react";
import PropTypes from "prop-types";
import Amex from "./amex";
import Arrow from "./arrow";
import Appointment from "./appointment";
import Archive from "./archive";
import Bag from "./bag";
import Bank from "./bank";
import Bell from "./bell";
import Back from "./back";
import BirdIcon from "./bird";
import Book from "./book";
import Calendar from "./calendar";
import Cat from "./cat";
import CatIcon from "./cat-for-biz-profile";
import Certification from "./certification";
import Check from "./check";
import Checked from "./checked";
import CircleCheckmark from "./circle-checkmark";
import Clock from "./clock";
import Close from "./close";
import CloseSmall from "./closesmall";
import CreditCard from "./credit-card";
import CreditCards from "./credit-cards";
import Delete from "./delete";
import Diners from "./diners";
import Discover from "./discover";
import Dog from "./dog";
import DogBone from "./dogbone";
import Dots from "./dots";
import DropdownArrow from "./dropdownarrow";
import Edit from "./edit";
import Eye from "./eye";
import Facebook from "./facebook";
import FacebookLogo from "./facebooklogo";
import FileCabinet from "./filecabinet";
import Filter from "./filter";
import FerretIcon from "./ferret";
import FishIcon from "./fish";
import FourSquare from "./fourSquares";
import GoogleLogo from "./googlelogo";
import Globe from "./globe";
import Email from "./happy-email";
import HolidayGiftCards from "./holidaygiftcards";
import Home from "./home";
import Info from "./info";
import Instagram from "./instagram";
import Jcb from "./jcb";
import LeftChevron from "./leftchevron";
import List from "./list";
import ListView from "./listview";
import Location from "./location";
import Logo from "./logo";
import LogoWithTm from "./logo-with-tm";
import LogoMobile from "./logomobile";
import Logout from "./logout";
import Magglass from "./magglass";
import MapCenter from "./mapcenter";
import MapView from "./mapview";
import Mail from "./mail";
import Mastercard from "./mastercard";
import Menu from "./menu";
import MiniCheck from "./minicheck";
import MiniClose from "./miniclose";
import NextArrow from "./nextarrow";
import Notification from "./notification";
import Paper from "./paper";
import People from "./people";
import Person from "./person";
import Personcheck from "./personcheck";
import PersonPlus from "./personplus";
import Pet from "./pet";
import Phone from "./phone";
import Plus from "./plus";
import PrivacyOptions from "./privacyoptions";
import RabbitIcon from "./rabbit";
import ReptileIcon from "./reptile";
import RightArrow from "./rightarrow";
import SadEmail from "./sad-email";
import Settings from "./settings";
import SmallMammalIcon from "./small-mammal";
import Smiley from "./smiley";
import Sort from "./sort";
import Statistic from "./statistic";
import Storefront from "./storefront";
import Stripe from "./stripe";
import ThumbsUp from "./thumbsup";
import Twitter from "./twitter";
import TwoPeople from "./twopeople";
import Unchecked from "./unchecked";
import Unionpay from "./unionpay";
import Unknown from "./unknown";
import Visa from "./visa";
import WildestLogo from "./wildestlogo";
import WisdomLogo from "./wisdomlogo";
import Wrench from "./wrench";
import Write from "./write";
import Quote from "./quote";

export const IconsList = {
  amex: Amex,
  archive: Archive,
  arrow: Arrow,
  appointment: Appointment,
  bag: Bag,
  bank: Bank,
  back: Back,
  bell: Bell,
  bird: BirdIcon,
  book: Book,
  calendar: Calendar,
  cat: Cat,
  catIcon: CatIcon,
  certification: Certification,
  check: Check,
  checked: Checked,
  circleCheckmark: CircleCheckmark,
  clock: Clock,
  close: Close,
  closeSmall: CloseSmall,
  creditCard: CreditCard,
  creditCards: CreditCards,
  delete: Delete,
  dinners: Diners,
  discover: Discover,
  dog: Dog,
  dogBone: DogBone,
  dots: Dots,
  dropdownArrow: DropdownArrow,
  edit: Edit,
  eye: Eye,
  facebook: Facebook,
  facebookLogo: FacebookLogo,
  ferretIcon: FerretIcon,
  fileCabinet: FileCabinet,
  filter: Filter,
  fish: FishIcon,
  fourSquares: FourSquare,
  googleLogo: GoogleLogo,
  globe: Globe,
  happyEmail: Email,
  holidayGiftCards: HolidayGiftCards,
  home: Home,
  info: Info,
  instagram: Instagram,
  jcb: Jcb,
  leftChevron: LeftChevron,
  list: List,
  listView: ListView,
  location: Location,
  logo: Logo,
  logoWithTm: LogoWithTm,
  logoMobile: LogoMobile,
  logout: Logout,
  mapCenter: MapCenter,
  magglass: Magglass,
  mail: Mail,
  mapView: MapView,
  mastercard: Mastercard,
  menu: Menu,
  miniCheck: MiniCheck,
  miniClose: MiniClose,
  nextArrow: NextArrow,
  notification: Notification,
  paper: Paper,
  people: People,
  person: Person,
  personcheck: Personcheck,
  personPlus: PersonPlus,
  pet: Pet,
  phone: Phone,
  plus: Plus,
  privacyoptions: PrivacyOptions,
  quote: Quote,
  rabbit: RabbitIcon,
  reptile: ReptileIcon,
  rightArrow: RightArrow,
  sadEmail: SadEmail,
  settings: Settings,
  smallMammal: SmallMammalIcon,
  smiley: Smiley,
  sort: Sort,
  statistic: Statistic,
  storeFront: Storefront,
  stripe: Stripe,
  thumbsUp: ThumbsUp,
  twitter: Twitter,
  twopeople: TwoPeople,
  unchecked: Unchecked,
  unionpay: Unionpay,
  unknown: Unknown,
  visa: Visa,
  wildestLogo: WildestLogo,
  wisdomLogo: WisdomLogo,
  wrench: Wrench,
  write: Write,
};

export default function Icon({ name, height, width, color, fill, classname, lineWidth, size, onClick }) {
  const IconComponent = IconsList[name];
  if (IconComponent) {
    return (
      <IconComponent
        color={color}
        height={height}
        width={width}
        fill={fill}
        className={classname}
        lineWidth={lineWidth}
        size={size}
        onClick={onClick}
      />
    );
  }
  return null;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  classname: PropTypes.string,
  lineWidth: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  height: undefined,
  width: undefined,
  size: undefined,
  lineWidth: undefined,
  color: "#000",
  fill: "none",
  classname: "",
  onClick: () => {},
};
