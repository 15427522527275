import React from "react";
import PropTypes from "prop-types";

export default function Back({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 15h-21m9.75 10.5L3.75 15l10.5-10.5"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

Back.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Back.defaultProps = {
  color: "#192617",
  height: "24",
  width: "24",
};
