import React from "react";
import PropTypes from "prop-types";

export default function LogoMobile({ color, height, width }) {
  return (
    <svg
      width={width || 53}
      height={height || 30}
      viewBox="0 0 53 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M26.0707 7.66353C26.7482 6.79081 27.3715 5.94535 28.049 5.15444C29.946 2.97262 32.1412 1.1999 34.9867 0.408987C40.1628 -1.00919 46.7753 1.44535 49.5666 6.05444C53.1439 11.9726 53.1168 17.9999 49.4853 23.8908C46.5314 28.6908 39.8105 31.1181 34.526 29.4272C32.087 28.6635 30.1899 27.0544 28.5368 25.1726C27.8051 24.3272 27.1547 23.3726 26.4772 22.4726C26.3688 22.309 26.2333 22.1726 26.0707 21.9544C25.5287 22.6635 25.0951 23.3453 24.526 23.9181C23.3065 25.1726 22.1141 26.509 20.759 27.5999C17.5883 30.1635 13.984 30.4635 10.217 29.3453C4.41758 27.6544 0.542244 22.609 0.0544389 16.4726C-0.243664 12.8454 0.433843 9.46353 2.35796 6.40899C4.74279 2.59081 8.32002 0.654442 12.7374 0.108988C17.4257 -0.46374 20.9758 1.60899 23.9569 5.01808C24.526 5.67262 25.0409 6.40899 25.5829 7.11808C25.7184 7.28171 25.881 7.44535 26.0707 7.66353ZM6.0165 14.3726C6.0165 16.6635 6.3688 18.2726 7.04631 19.8544C8.88913 24.109 15.0951 26.7817 19.3498 22.2544C20.3254 21.2181 21.3553 20.209 22.4393 19.309C24.8241 17.3181 27.3715 17.3181 29.7563 19.309C30.9216 20.2908 31.9515 21.409 33.0355 22.4453C36.0165 25.2817 40.1357 25.3363 43.1981 22.5272C46.9108 19.1181 47.2902 12.2453 44.0382 8.31808C41.7347 5.50899 36.6127 3.70899 32.8458 7.60899C31.9786 8.50899 31.0843 9.35444 30.1628 10.1999C27.6154 12.5454 24.6073 12.5726 22.0328 10.2272C21.003 9.2999 20.0273 8.34535 19.0246 7.39081C15.9894 4.44535 12.3038 4.99081 9.70214 6.8999C6.99211 8.8908 6.0707 11.7544 6.0165 14.3726Z"
          fill={color || "#FF7817"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={width || 53}
            height={height || 30}
            fill={color || "white"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}

LogoMobile.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

LogoMobile.defaultProps = {
  color: "white",
  height: undefined,
  width: undefined,
};
