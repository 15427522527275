import React from "react";
import PropTypes from "prop-types";

export default function List({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 5.8a.8.8 0 0 0 0 1.6h10.2a.8.8 0 0 0 0-1.6H3.6Zm14.4 0a.8.8 0 0 0 0 1.6h1.8a.8.8 0 0 0 0-1.6H18ZM2.8 12.6a.8.8 0 0 1 .8-.8h10.2a.8.8 0 0 1 0 1.6H3.6a.8.8 0 0 1-.8-.8Zm15.2-.8a.8.8 0 0 0 0 1.6h1.8a.8.8 0 0 0 0-1.6H18ZM2.8 18.6a.8.8 0 0 1 .8-.8h10.2a.8.8 0 0 1 0 1.6H3.6a.8.8 0 0 1-.8-.8Zm15.2-.8a.8.8 0 0 0 0 1.6h1.8a.8.8 0 0 0 0-1.6H18Z"
      />
    </svg>
  );
}

List.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

List.defaultProps = {
  width: "24",
  height: "24",
  fill: "#FF7817",
};
