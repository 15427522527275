import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LegacyIcon from "../icons/legacy-icon";

const StyledSpinner = styled.div`
  transform-origin: center;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  ${({ overridePositionAbsolute }) => (overridePositionAbsolute ? "" : "position: absolute;")}
  top: 0;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default function Spinner({ color, overridePositionAbsolute }) {
  return (
    <StyledSpinner overridePositionAbsolute={overridePositionAbsolute}>
      <LegacyIcon
        name="loading-spinner-20"
        color={color}
      />
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  color: PropTypes.string,
  overridePositionAbsolute: PropTypes.bool,
};

Spinner.defaultProps = {
  color: "#fff",
  overridePositionAbsolute: false,
};
