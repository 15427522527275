import React from "react";
import PropTypes from "prop-types";

export default function RightArrow({ className, color, height, width }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 11L6.5 6L1.5 1"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

RightArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

RightArrow.defaultProps = {
  className: "",
  color: "",
  height: "12",
  width: "8",
};
