import React from "react";

export default function Twitter() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1668 2.50008C18.3688 3.06298 17.4853 3.4935 16.5502 3.77508C16.0483 3.198 15.3813 2.78899 14.6394 2.60335C13.8974 2.41771 13.1164 2.46441 12.4019 2.73712C11.6874 3.00984 11.0739 3.49541 10.6443 4.12818C10.2148 4.76094 9.98991 5.51036 10.0002 6.27508V7.10841C8.53569 7.14639 7.08456 6.82159 5.776 6.16295C4.46745 5.50431 3.34209 4.53228 2.50016 3.33341C2.50016 3.33341 -0.83317 10.8334 6.66683 14.1667C4.9506 15.3317 2.90613 15.9159 0.833496 15.8334C8.3335 20.0001 17.5002 15.8334 17.5002 6.25008C17.4994 6.01796 17.4771 5.78641 17.4335 5.55841C18.284 4.71966 18.8842 3.66067 19.1668 2.50008V2.50008Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
