import React from "react";

export default function MiniClose() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7.5L22.7143 24.2143"
        stroke="#6D726B
        "
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.7143 7.5L6 24.2143"
        stroke="#6D726B
        "
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
