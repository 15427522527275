import React from "react";

export default function Delete() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 7.30768V15.7884C14.6667 16.4576 14.2024 17 13.6296 17H6.37037C5.79763 17 5.33334 16.4576 5.33334 15.7884V7.30768"
        stroke="#8F978C"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M13.5 6.23077V3.40385C13.5 3.18081 13.1518 3 12.7222 3H7.27778C6.84822 3 6.5 3.18081 6.5 3.40385V6.23077"
        stroke="#8F978C"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M3 6.76924H17"
        stroke="#8F978C"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}
