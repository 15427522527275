import React from "react";
import PropTypes from "prop-types";

export default function FourSquare(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5001 11.6667H11.6667V17.5001H17.5001V11.6667Z"
        stroke={color || "#192617"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 11.6667H2.5V17.5001H8.33333V11.6667Z"
        stroke={color || "#192617"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5001 2.5H11.6667V8.33333H17.5001V2.5Z"
        stroke={color || "#192617"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        stroke={color || "#192617"}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FourSquare.propTypes = {
  color: PropTypes.string,
};

FourSquare.defaultProps = {
  color: "",
};
