import React from "react";

export default function Checked() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="15"
        height="15"
        rx="4"
        fill="#FF7817"
      />
      <path
        d="M5 7.33333L6.76471 9L11 5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
