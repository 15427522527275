import React from "react";
import PropTypes from "prop-types";

export default function DogBone(props) {
  const { color } = props;
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m17.4651 7.89492c-.7447.74467-1.674.92717-2.5088.57896-.428-.17621-.8664-.26221-1.3111-.09859-.1846.06712-.3713.1741-.5097.31255-1.5796 1.56696-3.15075 3.14236-4.72401 4.71556-.34192.3419-.44051.7678-.37549 1.2272.02937.2139.0965.4237.17621.6251.3587.9041.10698 1.8984-.65028 2.534-.71112.5894-1.75996.6566-2.55288.1405-.56008-.3629-.8999-.8747-.97962-1.546-.07761-.6523-.58315-1.1579-1.23553-1.2271-.58525-.065-1.05094-.3419-1.40754-.795-.639796-.8076-.639797-1.9026-.01679-2.706.60623-.7825 1.64878-1.0489 2.59064-.6608.49925.2056 1.00479.2664 1.50404.0105.11957-.0609.23284-.1532.32933-.2496 1.54809-1.54394 3.08779-3.09203 4.64012-4.63173.3733-.36919.5097-.80341.4363-1.30895-.0294-.19718-.086-.39646-.1616-.58106-.3796-.93346-.109-1.9886.6734-2.59063.7971-.61252 1.8795-.61672 2.6829.00629.4636.3587.7468.83488.8118 1.42852.0735.63979.5685 1.13904 1.2041 1.22505.8936.11747 1.5019.61672 1.7935 1.45789.2895.84326.0839 1.59843-.409 2.13334z"
        stroke={color || "#192617"}
        strokeMiterlimit="10"
        strokeWidth="1.6"
      />
    </svg>
  );
}

DogBone.propTypes = {
  color: PropTypes.string,
};

DogBone.defaultProps = {
  color: "",
};
