import React from "react";
import PropTypes from "prop-types";

export default function Bell(props) {
  const { size, lineWidth, color } = props;
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.166 9.51777C15.166 10.19 15.1513 10.8635 15.1758 11.535C15.1821 11.7106 15.2668 11.9189 15.3872 12.0487C15.8135 12.5092 16.2713 12.9416 16.7151 13.3856C16.9601 13.6308 17.1085 14.51 16.9027 14.7785C16.8089 14.9008 16.5856 14.9868 16.4197 14.9889C15.1576 15.006 4.86681 14.9985 3.6264 14.9978C3.14269 14.9978 3.04749 14.8789 3.00829 14.4164C2.96209 13.8815 3.10489 13.4614 3.5396 13.1062C3.9204 12.7954 4.2592 12.4326 4.58471 12.0651C4.71001 11.9244 4.81081 11.7106 4.81361 11.5282C4.83461 10.2555 4.79541 8.9822 4.83741 7.71023C4.87941 6.42391 5.42611 5.31998 6.37882 4.44354C7.65073 3.27267 9.17675 2.7815 10.9114 3.09027C13.2711 3.50971 15.1408 5.49759 15.1653 7.90423C15.1702 8.44185 15.166 8.98015 15.166 9.51777Z"
        stroke={color || "white"}
        strokeWidth={lineWidth || "2"}
        strokeLinecap="round"
      />
      <path
        d="M7 15C7 16.6569 8.34315 18 10 18C11.6569 18 13 16.6569 13 15"
        stroke={color || "white"}
        strokeWidth={lineWidth || "2"}
        strokeLinecap="round"
      />
    </svg>
  );
}

Bell.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  lineWidth: PropTypes.string,
};

Bell.defaultProps = {
  color: "",
  size: "",
  lineWidth: "",
};
