import React from "react";
import PropTypes from "prop-types";

export default function Check(props) {
  const { color, size } = props;
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10.8132L7.52941 14.1465L16 6.14648"
        stroke={color || "#54C052"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Check.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Check.defaultProps = {
  color: "",
  size: 20,
};
