import React from "react";
import PropTypes from "prop-types";

export default function FileCabinet(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M5 1.66666L2.5 4.99999V16.6667C2.5 17.1087 2.67559 17.5326 2.98816 17.8452C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8452C17.3244 17.5326 17.5 17.1087 17.5 16.6667V4.99999L15 1.66666H5Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3332 8.33334C13.3332 9.2174 12.982 10.0652 12.3569 10.6904C11.7317 11.3155 10.8839 11.6667 9.99984 11.6667C9.11578 11.6667 8.26794 11.3155 7.64281 10.6904C7.01769 10.0652 6.6665 9.2174 6.6665 8.33334"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 5H17.5"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

FileCabinet.propTypes = {
  color: PropTypes.string,
};

FileCabinet.defaultProps = {
  color: "",
};
