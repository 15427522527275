import React from "react";
import PropTypes from "prop-types";

export default function NextArrow(props) {
  const { color } = props;
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L4.5 4.5L1 1"
        stroke={color || "#FF7817"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

NextArrow.propTypes = {
  color: PropTypes.string,
};

NextArrow.defaultProps = {
  color: "",
};
