import React from "react";
import PropTypes from "prop-types";

export default function Book(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M3.3335 16.25C3.3335 15.6975 3.55299 15.1676 3.94369 14.7769C4.33439 14.3861 4.86429 14.1667 5.41683 14.1667H16.6668"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.41683 1.66666H16.6668V18.3333H5.41683C4.86429 18.3333 4.33439 18.1138 3.94369 17.7231C3.55299 17.3324 3.3335 16.8025 3.3335 16.25V3.74999C3.3335 3.19746 3.55299 2.66755 3.94369 2.27685C4.33439 1.88615 4.86429 1.66666 5.41683 1.66666V1.66666Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

Book.propTypes = {
  color: PropTypes.string,
};

Book.defaultProps = {
  color: "",
};
