import React from "react";
import PropTypes from "prop-types";

export default function CreditCards(props) {
  const { color } = props;
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.8"
        y="29.8243"
        width="75.1333"
        height="49.3234"
        rx="8"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.9425 29.8636L14.7112 14.8979C15.5841 11.4315 19.1299 9.32201 22.631 10.1863L82.8541 25.0529C86.3551 25.9172 88.4857 29.4279 87.6128 32.8943L77.0888 71.434"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        x="9.15"
        y="63.7848"
        width="19.6"
        height="8.08581"
        rx="4.04291"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.8 41.5487H76.9333"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.8 52.8689H76.9333"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

CreditCards.propTypes = {
  color: PropTypes.string,
};

CreditCards.defaultProps = {
  color: "",
};
