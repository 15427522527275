import React from "react";
import PropTypes from "prop-types";

export default function CircleCheckmark(props) {
  const { color } = props;
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="45.4498"
        cy="44.5499"
        r="34.65"
        stroke={color || "#54C052"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M32.625 43.7851L39.9862 51.0412C40.7649 51.8087 42.0156 51.8087 42.7942 51.0412L57.825 36.2251"
        stroke={color || "#54C052"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

CircleCheckmark.propTypes = {
  color: PropTypes.string,
};

CircleCheckmark.defaultProps = {
  color: "",
};
