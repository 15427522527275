import React from "react";
import PropTypes from "prop-types";

export default function MapCenter({ width, height, color }) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m1.08331 8.58334 15.83329-7.5-7.49995 15.83336-1.66667-6.6667z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </svg>
  );
}

MapCenter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

MapCenter.defaultProps = {
  height: 18,
  width: 18,
  color: "#666666",
};
