import React from "react";
import PropTypes from "prop-types";

export default function LeftChevron({ className, color, height, width }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 1.5 11 L -4 6 L 1.5 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LeftChevron.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LeftChevron.defaultProps = {
  className: "",
  color: "#27272F",
  width: "12",
  height: "15",
};
