import React from "react";
import PropTypes from "prop-types";

export default function Smiley(props) {
  const { color } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6024 18.3332 9.99999C18.3332 5.39762 14.6022 1.66666 9.99984 1.66666C5.39746 1.66666 1.6665 5.39762 1.6665 9.99999C1.6665 14.6024 5.39746 18.3333 9.99984 18.3333Z"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.6665 11.6667C6.6665 11.6667 7.9165 13.3333 9.99984 13.3333C12.0832 13.3333 13.3332 11.6667 13.3332 11.6667"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 7.5H12.5083"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 7.5H7.50833"
          stroke={color || "#27272F"}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

Smiley.propTypes = {
  color: PropTypes.string,
};

Smiley.defaultProps = {
  color: "",
};
