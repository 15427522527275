import React from "react";
import PropTypes from "prop-types";

export default function Statistic({ className, color }) {
  return (
    <svg
      className={className}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9821 84.829H9.99885C7.45908 84.829 5.3999 82.7698 5.3999 80.2301V41.8214C5.3999 39.2816 7.45908 37.2224 9.99885 37.2224H24.9821C27.5219 37.2224 29.581 39.2816 29.581 41.8214V80.2301C29.581 82.7698 27.5219 84.829 24.9821 84.829Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.5811 41.7235C29.5811 41.7235 53.0065 34.955 43.1829 10.0182C41.5787 5.94674 66.3061 13.9598 57.1521 36.2691C56.7062 37.3565 57.5783 38.5278 58.7465 38.3948C65.5535 37.6225 82.9261 36.152 84.7443 41.0003C86.2382 44.9842 84.6347 47.403 83.4687 48.5365C83.0062 48.9846 83.0085 49.7154 83.4536 50.1793C85.014 51.8108 87.3392 55.5559 81.4964 59.7913C80.8262 60.2765 80.644 61.1946 81.0642 61.9072C82.3987 64.1742 84.2115 68.8887 77.7854 70.8769C77.0932 71.0907 76.7259 71.8449 76.9723 72.5265C77.8995 75.0904 79.1811 80.6521 73.4094 81.806C59.7463 84.5392 29.5811 81.8219 29.5811 81.8219"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7803 73.4942C22.7803 75.9977 20.7499 78.0281 18.2464 78.0281C15.7429 78.0281 13.7124 75.9977 13.7124 73.4942C13.7124 70.9907 15.7429 68.9602 18.2464 68.9602C20.7499 68.9602 22.7803 70.9907 22.7803 73.4942Z"
        stroke={color || "#FF7817"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Statistic.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Statistic.defaultProps = {
  className: "",
  color: "",
};
