import React from "react";
import PropTypes from "prop-types";

export default function MapView({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59998 3.60095C1.59998 2.77691 2.54074 2.30652 3.19998 2.80095L5.26664 4.35095C5.6222 4.61762 6.11109 4.61762 6.46664 4.35095L9.53331 2.05095C9.88886 1.78429 10.3778 1.78429 10.7333 2.05095L14 4.50095C14.2518 4.68981 14.4 4.98619 14.4 5.30095V12.401C14.4 13.225 13.4592 13.6954 12.8 13.201L10.7333 11.651C10.3778 11.3843 9.88886 11.3843 9.53331 11.651L6.46664 13.951C6.11109 14.2176 5.6222 14.2176 5.26664 13.951L1.99998 11.501C1.74817 11.3121 1.59998 11.0157 1.59998 10.701V3.60095Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5.99998 7.60095V10.401"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M9.99998 6.00098V8.80098"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

MapView.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

MapView.defaultProps = {
  height: 16,
  width: 16,
  color: "#8F978C",
};
