import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme, css } from "styled-components";
import LegacyIcon from "../icons/legacy-icon";
import Spinner from "../Spinner";

const Button = styled.button`
  display: flex;
  justify-content: ${(props) => {
    return props.TrailingIcon ? "space-between" : null;
  }};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize(2)};
  height: 2.625rem;
  line-height: 2.625rem;
  margin: 0;
  width: 100%;
  border-radius: 3rem;
  cursor: pointer;
  position: relative;
  color: ${(props) => {
    if (!props.textColor && props.outlineType === "solid") {
      return "white";
    }
    if ((!props.textColor && props.outlineType === "outline") || (!props.textColor && props.outlineType === "subtle")) {
      return props.theme.themeColors[props.colorType || "primary"];
    }

    return props.theme.themeColors[props.textColor || "primary"];
  }};
  border: ${(props) => {
    if (props.outlineType === "outline") {
      return `0.0625rem solid ${props.theme.themeColors[props.colorType || "primary"]}`;
    }
    return "none";
  }};
  background-color: ${(props) => {
    if (props.outlineType === "outline") {
      if (props.outlineTransparent) {
        return "transparent";
      }
      return props.theme.colors.white;
    }
    if (props.outlineType === "subtle") {
      return `${props.theme.themeColors[props.colorType || "primary"]}25`;
    }
    if (props.outlineType === "none") {
      return "transparent";
    }
    return props.theme.themeColors[props.colorType || "primary"];
  }};
  padding: 0;

  :hover {
    opacity: 0.65;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  transition: ${({ theme }) => theme.opacityTransition};

  :focus {
    outline: 0;
  }

  :focus-visible {
    outline: 0.125rem solid #0070f3;
  }
`;

const LeadingIconWrapper = styled.div`
  position: relative;
  line-height: 0;
  margin: auto 0;
  left: 1.0625rem;

  width: 0;
  height: 1.5rem;
  opacity: 0;

  &.expand {
    width: 1.75rem;
    opacity: 1;
  }

  ${({ hasSubmittingIcon }) =>
    hasSubmittingIcon &&
    css`
      bottom: 0;
      left: 8px;
      right: 0;
      top: 0;
      margin: auto 0;
      position: absolute;
      opacity: 1;
      width: 1.75rem;
    `}
`;

const TrailingIconWrapper = styled.div`
  margin: auto 0.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin: auto;

  ${({ hasNoTextMargins }) =>
    hasNoTextMargins &&
    css`
      margin: 0;
    `};
`;

const AltButton = styled.button`
  border-radius: 100%;
  height: 2.625rem;
  width: 2.625rem;
  cursor: pointer;
  color: ${(props) => {
    return !props.colorType || props.outlineType === "solid" ? "white" : props.theme.themeColors[props.colorType || "primary"];
  }};
  border: ${(props) => {
    if (props.outlineType === "outline") {
      return `0.0625rem solid ${props.theme.themeColors[props.colorType || "primary"]}`;
    }
    return "none";
  }};
  background-color: ${(props) => {
    if (props.outlineType === "outline") {
      return "white";
    }
    if (props.outlineType === "subtle") {
      return `${props.theme.subtleColors[props.colorType || "primary"]}`;
    }
    return props.theme.themeColors[props.colorType || "primary"];
  }};

  :hover {
    opacity: 0.65;
  }

  transition: ${({ theme }) => theme.opacityTransition};

  :focus {
    outline: 0;
  }

  :focus-visible {
    outline: 0.125rem solid #0070f3;
  }
`;

const SingleIcon = styled.div`
  margin: auto;
  line-height: 0;
`;

const ButtonComponent = React.forwardRef(
  ({ TrailingIcon, spinnerColor, LeadingIcon, hasNoTextMargins, showSpinner, showSuccess, onClick, ...props }, ref) => {
    const { children } = props;
    if (!children || !Object.keys(children).length) {
      return (
        <AltButton
          TrailingIcon={TrailingIcon}
          LeadingIcon={LeadingIcon}
          {...props}
          onClick={onClick}
          ref={ref}
        >
          <SingleIcon>{LeadingIcon && LeadingIcon()}</SingleIcon>
        </AltButton>
      );
    }

    return (
      <Button
        TrailingIcon={TrailingIcon}
        LeadingIcon={LeadingIcon}
        {...props}
        onClick={onClick}
        ref={ref}
      >
        {(LeadingIcon || showSpinner || showSuccess) && (
          <LeadingIconWrapper
            className={LeadingIcon ? "expand" : ""}
            hasSubmittingIcon={showSpinner || showSuccess}
          >
            {showSpinner && !showSuccess && (
              <Spinner
                size={24}
                color={spinnerColor}
              />
            )}

            {showSuccess && (
              <LegacyIcon
                name="checkmark-20"
                color="white"
              />
            )}

            {LeadingIcon && !showSuccess && !showSpinner && LeadingIcon}
          </LeadingIconWrapper>
        )}

        {Object.keys(children).length && (
          <TextWrapper
            TrailingIcon={TrailingIcon}
            LeadingIcon={LeadingIcon}
            hasNoTextMargins={hasNoTextMargins}
          >
            {children}
          </TextWrapper>
        )}

        {TrailingIcon && <TrailingIconWrapper>{TrailingIcon}</TrailingIconWrapper>}
      </Button>
    );
  }
);

export default withTheme(ButtonComponent);

ButtonComponent.propTypes = {
  TrailingIcon: PropTypes.any,
  LeadingIcon: PropTypes.func,
  hasNoTextMargins: PropTypes.bool,
  showSpinner: PropTypes.bool,
  showSuccess: PropTypes.bool,
  spinnerColor: PropTypes.string,
  colorType: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "dark",
    "warning",
    "success",
    "darkGrey",
    "lightBlue",
    "lightGreen3",
    "darkGreen1",
    "darkGreen4",
    "black",
    "blue1",
    "brown",
    "white",
    "orange1",
    "none",
    "disabled",
  ]),
  outlineType: PropTypes.oneOf(["solid", "subtle", "outline", "none"]),
  onClick: PropTypes.func,
  outlineTransparent: PropTypes.bool,
  theme: PropTypes.shape({ themeColors: PropTypes.shape({}) }),
  children: PropTypes.node,
};

ButtonComponent.defaultProps = {
  TrailingIcon: null,
  LeadingIcon: null,
  hasNoTextMargins: false,
  colorType: "primary",
  spinnerColor: undefined,
  outlineType: "solid",
  onClick: () => {},
  outlineTransparent: true,
  theme: {},
  children: null,
  showSpinner: false,
  showSuccess: false,
};
