import React from "react";
import PropTypes from "prop-types";

export default function Location({ className, color, size, fill }) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={fill ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 10C15.5097 12.4515 11 18 10 18C9.50001 18 4.53667 12.9516 4.00001 10C3 4.5 6.6863 2 10 2C13.3137 2 17 5 16 10Z"
        stroke={color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10Z"
        stroke={fill ? "white" : color || "#8F978C"}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
}

Location.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  fill: PropTypes.bool,
};

Location.defaultProps = {
  className: "",
  color: "",
  size: 20,
  fill: false,
};
